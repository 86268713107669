import React from "react";

const LoaderComp = ({ classNameText = "secondary" }) => {
  return (
    <div className={`spinner-grow text-${classNameText}`} role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default LoaderComp;
