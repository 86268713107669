import { TooltipCheckboxMapComp } from "components/controls/checkbox";
import { TooltipWrapper } from "components/tooltip";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";

const Maps = () => {
  const mapRef = React.useRef(null);
  let map;
  let trafficLayer;
  // let weatherLayer;

  const toggleTraffic = () => {
    //traffic layer is disabled.. enable it
    if (trafficLayer.getMap() == null) {
      trafficLayer.setMap(map);
      // weatherLayer.setMap(map);
    } else {
      //traffic layer is enabled.. disable it
      trafficLayer.setMap(null);
      // weatherLayer.setMap(null);
    }
  };

  useEffect(() => {
    map = mapRef.current;
    let google = window.google;
    let lat = "36.161030";
    let lng = "-86.777190";
    const myLatlng = new google.maps.LatLng(lat, lng);
    const mapOptions = {
      zoom: 12,
      center: myLatlng,
      scrollwheel: false,
      zoomControl: true,
      styles: [
        {
          featureType: "administrative",
          elementType: "labels.text.fill",
          // stylers: [{ color: "#444444" }],
        },
        {
          featureType: "landscape",
          elementType: "all",
          // stylers: [{ color: "#f2f2f2" }],
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "road",
          elementType: "all",
          stylers: [{ saturation: -100 }, { lightness: 45 }],
        },
        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [{ visibility: "simplified" }],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "water",
          elementType: "all",
          // stylers: [{ color: "#5e72e4" }, { visibility: "on" }],
          stylers: [{ visibility: "on" }],
        },
      ],
    };
    map = new google.maps.Map(map, mapOptions);
    const toggleBounce = () => {
      if (marker.getAnimation() !== null) {
        marker.setAnimation(null);
      } else {
        marker.setAnimation(google.maps.Animation.BOUNCE);
      }
    };
    const marker = new google.maps.Marker({
      position: myLatlng,
      map: map,
      draggable: true,
      animation: google.maps.Animation.DROP,
      title: "Drag me",
    });
    trafficLayer = new google.maps.TrafficLayer();

    // weatherLayer = new google.maps.weather.WeatherLayer({
    //   temperatureUnits: google.maps.weather.TemperatureUnit.CELSIUS, // or FAHRENHEIT
    // });
    marker.addListener("click", toggleBounce);
    google.maps.event.addDomListener(
      document.getElementById("traffic"),
      "click",
      toggleTraffic
    );
    // const contentString =
    //   '<div class="info-window-content"><h2>Downtown Nashville, TN</h2>' +
    //   "<p>Downtown Nashville, TN</p></div>";

    // const infowindow = new google.maps.InfoWindow({
    //   content: contentString,
    // });

    google.maps.event.addListener(marker, "click", function () {
      // infowindow.open(map, marker);
    });
  }, []);

  const displayOptionsArray = [
    {
      name: "DRIVERS",
      checkId: "drivers",
    },
    {
      name: "DRIVER PATHS",
      checkId: "driver-paths",
    },
    {
      name: "TRAFFIC",
      checkId: "traffic",
    },
    {
      name: "PRECIPITATION",
      checkId: "weather",
    },
  ];

  return (
    <>
      <Row className="icon-examples mt-0 flex-wrap d-flex pb-3">
        <Col sm="12 mb-3 d-flex">
          <h3 className="ml-0 ml-md-2 ml-xl-3  font-weight-medium">
            <TooltipWrapper id="wsdfsderwerewr" textTwo="Layers">
              LAYERS
            </TooltipWrapper>
          </h3>
        </Col>
        {displayOptionsArray.map((el, ind) => (
          <Col
            sm={6}
            xl={3}
            key={ind}
            className="pl-4 pl-md-5 pl-xl-6 mb-1 mb-md-3"
          >
            <TooltipCheckboxMapComp id={el.checkId} name={el.name} />
          </Col>
        ))}
      </Row>
      <div
        style={{ height: `400px` }}
        className="map-canvas"
        id="map-canvas"
        ref={mapRef}
      ></div>
    </>
  );
};

export default Maps;
