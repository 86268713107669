import React, { useEffect } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import $ from "jquery";
import "select2";
import classNames from "classnames";
import InputStrap from "components/controls/input-strap";
import { CheckboxWithCheckedComp } from "components/controls/checkbox";
import { CheckboxCompTwo } from "components/controls/checkbox";
import "../../assets/css/dispatcher.css";

import GoogleInput from "components/controls/google-input";

const FulfilmentPickupForm = ({
  bgFormColor,
  defaultClass,
  tabNumber,
  activeIndex,
  activeClass,
  formId,
  isFDPTrue,
  setIsFDPTrue,
  isTabTrue,
  setIsTabTrue,
  formData,
  setFormData,
  enableCheckBox,
  setEnableCheckBox,
  overrideDeliveryAdress,
  setOverrideDeliveryAdress,
  // isFstChecked,
  // setIsFstChecked,
  deliveryProviderArray,
  selectAddressArray,
}) => {
  useEffect(() => {
    $("select").select2();
    $(`#${formId}5`).change(handleClick);
    $(`#${formId + tabNumber}`).change(handleFDPChange);
  }, []);
  const handleFDPChange = (e) => {
    setIsFDPTrue(e.target.value);
  };
  const handleClick = (e) => {
    setIsTabTrue(e.target.value);
  };

  const handleOrderDataChange = (e, callbackFun) => {
    const { name, value } = e.target;
    callbackFun((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div>
      <div
        style={{ backgroundColor: bgFormColor }}
        className={classNames(
          defaultClass,
          {
            [`${activeClass}`]: activeIndex === tabNumber,
          },
          {
            "d-none": activeIndex !== tabNumber,
          }
        )}
        id={formId}
        role="tabpanel"
        aria-labelledby={`${formId}-list`}
      >
        <div className="d-flex flex-wrap text-dark">
          <Row className="w-100 d-flex flex-wrap m-0">
            <Col md={6} className="px-2 px-sm-3">
              <FormGroup col>
                <Label>Inventory Location</Label>
                <Col className="p-0">
                  <Input
                    name="select"
                    className="form-control-alternative"
                    type="select"
                    style={{ color: "#000" }}
                  >
                    {activeIndex === 1 ? (
                      <>
                        <option>Nashville</option>
                        <option>Atlanta</option>
                        <option>Chattanooga</option>
                        <option>Wichita 1</option>
                      </>
                    ) : activeIndex === 2 ? (
                      <>
                        <option>Atlanta</option>
                        <option>Nashville</option>
                        <option>Chattanooga</option>
                        <option>Wichita 1</option>
                      </>
                    ) : activeIndex === 3 ? (
                      <>
                        <option>Chattanooga</option>
                        <option>Atlanta</option>
                        <option>Nashville</option>
                        <option>Wichita 1</option>
                      </>
                    ) : activeIndex === 4 ? (
                      <>
                        <option>Wichita 1</option>
                        <option>Chattanooga</option>
                        <option>Atlanta</option>
                        <option>Nashville</option>
                      </>
                    ) : (
                      ""
                    )}

                    <option>Clarksville</option>
                  </Input>
                </Col>
              </FormGroup>
            </Col>

            <Col md={6} className="px-2 px-sm-3">
              <FormGroup col>
                <Label>1st Delivery Provider</Label>
                <Col className="p-0">
                  <select
                    id={formId + tabNumber}
                    value={isFDPTrue}
                    className="select2 w-100 form-control-alternative js-example-basic-single"
                  >
                    {deliveryProviderArray.map((el) => (
                      <option key={el}>{el}</option>
                    ))}
                  </select>
                  {/* </Input> */}
                </Col>
              </FormGroup>
            </Col>
            <Col md={6} className="px-2 px-sm-3">
              <FormGroup col>
                <Label>Select Address</Label>
                <Col className="p-0">
                  <select
                    id={formId + "5"}
                    value={isTabTrue}
                    className="select2 w-100 form-control-alternative js-example-basic-single"
                  >
                    {selectAddressArray.map((el) => (
                      <option key={el.keyValue} value={el.keyValue}>
                        {el.keyName}
                      </option>
                    ))}
                  </select>
                  {/* </Input> */}
                </Col>
              </FormGroup>
            </Col>
            <Col md={6} className="px-2 px-sm-3">
              <InputStrap
                label="Name"
                onChange={(e) => handleOrderDataChange(e, setFormData)}
                type="text"
                name="name"
                className="form-control-alternative"
                value={formData.name}
              />
            </Col>

            <Col md={6} className="px-2 px-sm-3">
              <Label>Input Address</Label>
              <GoogleInput
                formData={formData}
                setFormData={setFormData}
                placeholder={"Enter your pickup address"}
              />
            </Col>

            <Col md={6} className="px-2 px-sm-3">
              <InputStrap
                label="Phone"
                className="form-control-alternative"
                value={formData.phone}
                onChange={(e) => handleOrderDataChange(e, setFormData)}
                type="text"
                name="phone"
              />
            </Col>

            <Col md={6} className="px-2 px-sm-3">
              <InputStrap
                label="Pickup Instructions"
                className="form-control-alternative"
                value={formData.instructions}
                onChange={(e) => handleOrderDataChange(e, setFormData)}
                rows="3"
                type="textarea"
                name="instructions"
              />
            </Col>

            <Col md={6} className="px-2 px-sm-3">
              <InputStrap
                labelChildren={
                  <Label className="d-flex p-0 mb-2 justify-content-between col-form-label px-0">
                    Override Delivery Instructions
                    <CheckboxWithCheckedComp
                      id={`Override${tabNumber}`}
                      checkBoxName="deliveryinstructions"
                      name="Enabled"
                      checkboxValue={enableCheckBox}
                      handleChange={() => {
                        setOverrideDeliveryAdress("");
                        setEnableCheckBox((prev) => !prev);
                      }}
                    />
                  </Label>
                }
                disabled={!enableCheckBox}
                value={overrideDeliveryAdress}
                onChange={(e) => setOverrideDeliveryAdress(e.target.value)}
                rows="3"
                type="textarea"
              />
            </Col>

            {/* <Col lg={6} className="pl-3 px-sm-3 d-flex flex-column">
              <CheckboxCompTwo
                id={`bnadsfsdfsdf${tabNumber}`}
                name="Send Confirmation/ETA Text to 3PL Drivers?"
                checkboxValue={isFstChecked}
                handleChange={() => setIsFstChecked((prev) => !prev)}
              />
            </Col>
            <Col lg={6} className="px-2 px-sm-3 mt-3 mt-lg-0">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  defaultValue={
                    isFstChecked
                      ? "Ken (XpressRun) here for your Roadie. What’s your ETA? :)"
                      : ""
                  }
                  type="text"
                />
              </FormGroup>
            </Col> */}

            {/* <Col lg={6} className="pl-3 px-sm-3 d-flex flex-column pb-4">
              <CheckboxCompTwo
                id="bnasdfsdffsdf"
                name="Send Any Location Photos to 3PL Drivers?"
              />
              <CheckboxCompTwo
                id="bnasdfsdfsdfsdfwerwer"
                name="Send Vehicle Photo to 3PL Drivers?"
              />
            </Col> */}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default FulfilmentPickupForm;
