import { TooltipComp } from "components/tooltip";
import React, { useState } from "react";

export const CheckboxCompTwo = ({
  checkboxValue = false,
  id,
  name,
  checkBoxName = "checkboxName",
  handleChange = () => {},
  className,
}) => {
  return (
    <div
      className={`custom-control custom-checkbox mb-1 ${
        className ? "abc" : ""
      }`}
    >
      <input
        className="custom-control-input"
        id={id}
        type="checkbox"
        defaultChecked={checkboxValue}
        onChange={handleChange}
        name={checkBoxName}
      />
      <label className="custom-control-label" htmlFor={id}>
        {name}
      </label>
    </div>
  );
};

export const CheckboxWithCheckedComp = ({
  checkboxValue = false,
  id,
  name,
  checkBoxName = "checkboxName",
  handleChange = () => {},
}) => {
  return (
    <div
      className={`custom-checkbox ml-4 d-flex mb-0 mb-md-1 ${
        name === "Enabled" ? "mt-1 mt-sm-0" : ""
      }`}
    >
      <input
        className="custom-control-input "
        id={id + name}
        style={{
          position: "static",
        }}
        checked={checkboxValue}
        onChange={handleChange}
        name={checkBoxName}
        type="checkbox"
      />
      <label className="custom-control-label" htmlFor={id + name}>
        <span style={{ left: "-20px" }}>{name && name}</span>
      </label>
    </div>
  );
};

export const TooltipCheckboxComp = ({
  id,
  name,
  handleChange = () => {},
  checkBoxName = "checkboxName",
  checkboxValue,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const handleFunChange = () => {
    handleChange();
    setIsChecked((prev) => !prev);
  };
  return (
    <div className="custom-checkbox ml-4 d-flex mb-0 mb-md-1 w-100">
      <input
        className="custom-control-input "
        id={id + name}
        style={{
          position: "static",
        }}
        defaultChecked={
          checkboxValue ? JSON.stringify(checkboxValue) : isChecked
        }
        onChange={handleFunChange}
        name={checkBoxName}
        type="checkbox"
      />
      <label className="custom-control-label" htmlFor={id + name}>
        <TooltipComp
          anchorId={id}
          textOne="Check"
          textTwo="Uncheck"
          varName={checkboxValue ? !JSON.stringify(checkboxValue) : !isChecked}
        />
        <div id={id}>
          <span style={{ left: "-20px" }}>{name && name}</span>
        </div>
      </label>
    </div>
  );
};

export const TooltipCheckboxMapComp = ({
  id,
  name,
  handleChange = () => {},
  checkBoxName = "checkboxName",
  checkboxValue,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const handleFunChange = () => {
    handleChange();
    setIsChecked((prev) => !prev);
  };
  return (
    <div className="custom-checkbox ml-4 d-flex mb-0 mb-md-1">
      <input
        className="custom-control-input "
        id={id}
        style={{
          position: "static",
        }}
        defaultChecked={
          checkboxValue ? JSON.stringify(checkboxValue) : isChecked
        }
        onChange={handleFunChange}
        name={checkBoxName}
        type="checkbox"
      />
      <label className="custom-control-label" htmlFor={id}>
        <TooltipComp
          anchorId={id}
          textOne="Check"
          textTwo="Uncheck"
          varName={checkboxValue ? !JSON.stringify(checkboxValue) : !isChecked}
        />
        <div id={id}>
          <span style={{ left: "-20px" }}>{name && name}</span>
        </div>
      </label>
    </div>
  );
};
