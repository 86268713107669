import { getDateGMT } from "./date-methods";
import { toastSuccess } from "./toast";

const convertArrayToCSV = (
  array,
  columnArray,
  dispatchResult,
  requoteResult
) => {
  const filteredData = columnArray.filter((column) => column.isChecked);
  const rows = [filteredData.map((column) => column.name)];
  array.forEach((row) => {
    const rowData = [];
    filteredData.forEach((column) => {
      if (column.isChecked) {
        if (column.dataKey === "order_amount_shipping") {
          rowData.push(
            "$" + (+row[column.dataKey] + +row.order_amount_gratuity)
          );
        } else if (
          column.dataKey === "localdelivery_driver_display_internal_name"
        ) {
          let fdpValue = dispatchResult?.order_ids?.hasOwnProperty(
            row.order_source_ordername
          )
            ? dispatchResult?.order_ids[row.order_source_ordername].data
                ?.first_delivery_provider
            : row[column.dataKey]
            ? row[column.dataKey]
            : "N/A";
          rowData.push(fdpValue);
        } else if (column.dataKey === "shipping_cost_estimated") {
          let fdpValue = requoteResult?.estimation_ids?.hasOwnProperty(
            row.order_source_ordername
          )
            ? "$" +
              requoteResult?.estimation_ids[row.order_source_ordername].data
                ?.price
            : row[column.dataKey]
            ? "$" + row[column.dataKey]
            : "N/A";
          rowData.push(fdpValue);
        } else if (column.dataKey === "order_amount_cart") {
          rowData.push("$" + row[column.dataKey]);
        } else if (
          column.dataKey === "mkt_tip" ||
          column.dataKey === "shipping_cost_estimated"
        ) {
          rowData.push(row[column.dataKey] ? "$" + row[column.dataKey] : "N/A");
        } else if (column.dataKey === "order_placed_utc") {
          const date = getDateGMT(row[column.dataKey]).replace(/,/, "");
          rowData.push(date);
        } else {
          rowData.push(
            row[column.dataKey] ? row[column.dataKey].toUpperCase() : "N/A"
          );
        }
      }
    });
    rows.push(rowData);
  });

  return rows.map((row) => row.join(",")).join("\n");
};

export const handleDownloadFun = async (
  array,
  columnArray,
  dispatchResult,
  requoteResult,
  handleCSVModalClose
) => {
  const csvContent = convertArrayToCSV(
    array,
    columnArray,
    dispatchResult,
    requoteResult
  );
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  try {
    handleCSVModalClose();
    const blobUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = blobUrl;
    downloadLink.download = "data.csv";
    downloadLink.click();
    URL.revokeObjectURL(blobUrl);
    toastSuccess("CSV file generated successfully");
  } catch (error) {
    handleCSVModalClose();
    console.error("Error saving file:", error);
  }
};
