import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isCustomerNameChecked:
    JSON.parse(localStorage.getItem("nameChecked")) === null
      ? true
      : JSON.parse(localStorage.getItem("nameChecked")),
  isCustomerDeliveryZipChecked: JSON.parse(
    localStorage.getItem("deliveryZipChecked")
  ),
  dataTableArray: [],
  preserveDataTableArray: [],
  updatedTableArray: [],
};

export const dispatcherSlice = createSlice({
  name: "dispatcher",
  initialState,
  reducers: {
    getUserNameChecked: (state) => {
      localStorage.setItem("nameChecked", !state.isCustomerNameChecked);
      state.isCustomerNameChecked = !state.isCustomerNameChecked;
    },
    getZipCodeChecked: (state) => {
      localStorage.setItem(
        "deliveryZipChecked",
        !state.isCustomerDeliveryZipChecked
      );
      state.isCustomerDeliveryZipChecked = !state.isCustomerDeliveryZipChecked;
    },
    getOrderListTable: (state, action) => {
      state.dataTableArray = action.payload;
    },
    getUpdatedOrderListTable: (state, action) => {
      state.dataTableArray = action.payload;
    },
    setPreserveOrderListTable: (state, action) => {
      state.preserveDataTableArray = action.payload;
    },

    getOrderSortedList: (state, action) => {
      state.dataTableArray = action.payload;
    },
    clearTableArrayData: (state) => {
      state.dataTableArray = [];
    },
    clearAllData: (state) => {
      state.dataTableArray = [];
      state.preserveDataTableArray = [];
      state.isCustomerNameChecked = true;
    },
  },
});

export const {
  getUserNameChecked,
  getZipCodeChecked,
  getOrderListTable,
  getOrderSortedList,
  setPreserveOrderListTable,
  clearAllData,
  clearTableArrayData,
  getUpdatedOrderListTable,
} = dispatcherSlice.actions;

export default dispatcherSlice.reducer;

// ?   eliminate which  are not included
// ?
// ?   get elements which are included in preseverd table
// ?   get again which are new selections from current list
// ?   merge which are not included and latest selections
