// reactstrap components
import { useLocation } from "react-router-dom";
import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getUserLoggedIn } from "redux/store/reducer/auth-reducer";
import { loginAction } from "actions/authAction";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import http from "api";
import { setStorageItem } from "utils/local-storage-func";

const formSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter valid email")
    .required("Please enter your email"),
  password: Yup.string()
    .required("Please enter your password")
    .min(3, "Enter minimum three characters"),
});
const formOptions = { resolver: yupResolver(formSchema) };

const Login = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);
  const state = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  let history = useHistory();
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    isUserLoggedIn();
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const isUserLoggedIn = () => {
    const userStatus = localStorage.getItem("isUserLogged");
    if (userStatus) {
      history.push("/");
    }
  };

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (!formData.email.length) {
      formIsValid = false;
      errors.email = "*Please enter your email-ID.";
    }

    if (formData.email.length) {
      //regular expression for email validation
      var pattern = new RegExp(
        /^((?!^[0-9])[_a-zA-Z0-9-]+)(\.[_a-zA-Z0-9-]+)*@([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,3})/i
      );
      if (!pattern.test(formData.email)) {
        formIsValid = false;
        errors.email = "*Please enter valid email-ID.";
      }
    }

    if (!formData.password.length) {
      formIsValid = false;
      errors.password = "*Please enter your password.";
    }

    if (formData.password.length < 3) {
      if (
        !formData.password.match(
          /^.*(?=.{2,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
        )
      ) {
        formIsValid = false;
        errors.password = "*Please enter minimum three characters.";
      }
    }

    setError(errors);
    return formIsValid;
  };
  const handleFunSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      loginAction(formData)
        .then((res) => {
          if (+res.status == 200) {
            if (res.data.token) {
              toast.success("You are successfully logged in.");
              setStorageItem("isUserLogged", true);
              setStorageItem("userData", JSON.stringify(res.data.user));

              setStorageItem("token", res.data.token);
              setStorageItem(
                "getUserTimeSession",
                JSON.stringify(new Date().getTime())
              );
              history.push("/");
              dispatch(getUserLoggedIn(res.data));
              http.defaults.headers.common.Authorization =
                "Bearer " + res?.data?.token;
            } else {
              toast.error(res?.data?.data?.message);
            }
          } else {
            toast.error("Server error");
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.response?.data?.message);
        })
        .finally(() => {
          setLoading(false);
          localStorage.removeItem("currentPageOrderList");
          localStorage.removeItem("preservedOrderList");
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((pre) => {
      return {
        ...pre,
        [name]: value,
      };
    });
  };
  return (
    <>
      <div className="main-content" ref={mainContent}>
        {/* <AuthNavbar /> */}
        <div className="header bg-gradient-info py-7 py-lg-8">
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>Login</small>
                  </div>
                  <Form role="form" onSubmit={handleFunSubmit}>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email"
                          name="email"
                          required
                          onChange={handleChange}
                          defaultValue={formData.email}
                          autoComplete="new-email"
                        />
                      </InputGroup>
                      <div className="text-danger mt-2">{error.email}</div>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          type="password"
                          required
                          name="password"
                          onChange={handleChange}
                          defaultValue={formData.password}
                          autoComplete="new-password"
                        />
                      </InputGroup>
                      <div className="text-danger mt-2">{error.password}</div>
                    </FormGroup>
                    {/* <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id=" customCheckLogin"
                        type="checkbox"
                        onChange={handleChangeCheckbox}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor=" customCheckLogin"
                      >
                        <span className="text-muted">Remember me</span>
                      </label>
                    </div> */}
                    <div className="text-center">
                      <Button
                        className="my-4"
                        color="primary"
                        type="submit"
                        disabled={loading}
                      >
                        Sign in
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              {/* <Row className="mt-3">
                <Col xs="6">
                  <a
                    className="text-light"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <small>Forgot password?</small>
                  </a>
                </Col>
                <Col className="text-right" xs="6">
                  <a
                    className="text-light"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <small>Create new account</small>
                  </a>
                </Col>
              </Row> */}
            </Col>
          </Row>
        </Container>
      </div>
      {/* <AuthFooter /> */}
    </>
  );
};

export default Login;
