import {
  clearLocalStorage,
  getStorageItem,
  setStorageItem,
} from "./local-storage-func";

const timeZoneStr = "en-US";

export const getDateGMT = (date) => {
  return new Date(date).toLocaleString(timeZoneStr, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const getTimeGMT = (date) => {
  return new Date(date).toLocaleString(timeZoneStr, {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const checkUserSession = () => {
  // Get the current timestamp
  const currentTimestamp = new Date().getTime();
  const userTimeSession = JSON.parse(getStorageItem("getUserTimeSession"));
  // Assuming you have another timestamp stored in `previousTimestamp`

  // Calculate the time difference in milliseconds
  const timeDifference = currentTimestamp - +userTimeSession;

  // Convert the time difference to hours
  const timeDifferenceInHours = timeDifference / (1000 * 60 * 60);

  // Check if the time difference is less than or equal to 4 hours
  if (timeDifferenceInHours <= 4) {
    setStorageItem("getUserTimeSession", JSON.stringify(currentTimestamp));
  } else {
    clearLocalStorage();
    window.location.href = "/login";
  }
};
