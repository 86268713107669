import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

export const TooltipComp = ({
  anchorId,
  textOne,
  place = "top",
  textTwo,
  varName,
}) => {
  return (
    <Tooltip
      anchorId={anchorId}
      place="top"
      content={varName ? textOne : textTwo}
    />
  );
};

export const TooltipWrapper = ({ children, place = "top", textTwo, id }) => {
  return (
    <>
      <TooltipComp
        anchorId={id}
        textOne="Checked"
        place={place}
        textTwo={textTwo}
      />
      <div id={id}>{children && children}</div>
    </>
  );
};
