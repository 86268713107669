import { useMemo, useState } from "react";

export const useSortableData = (dataList, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = useMemo(() => {
    if (sortConfig !== null && dataList?.length) {
      return dataList.slice().sort((a, b) => {
        if (
          sortConfig.key === "order_amount_cart" &&
          sortConfig.direction === "ascending"
        ) {
          return +a.order_amount_cart - +b.order_amount_cart;
        }
        if (
          sortConfig.key === "order_amount_cart" &&
          sortConfig.direction === "descending"
        ) {
          return +b.order_amount_cart - +a.order_amount_cart;
        }
        if (
          a[sortConfig.key] < b[sortConfig.key] &&
          sortConfig.key !== "order_amount_cart"
        ) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (
          a[sortConfig.key] > b[sortConfig.key] &&
          sortConfig.key !== "order_amount_cart"
        ) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return dataList;
  }, [dataList, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { dataList: sortedItems, requestSort, sortConfig };
};
