import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Login from "views/examples/Login";

import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import Dispatcher from "views/examples/dispatcher";
import AdminRoutes from "router-config/admin-routes";
import { ToastContainer } from "react-toastify";

export default () => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          {/* <AdminRoutes path="/dispatcher" exact>
            <PanelRoutes>
              <Index />
            </PanelRoutes>
          </AdminRoutes> */}
          <AdminRoutes path="/" exact>
            <PanelRoutes>
              <Dispatcher />
            </PanelRoutes>
          </AdminRoutes>
          {/* <AdminRoutes path="/delivery" exact>
            <PanelRoutes>
              <Delivery />
            </PanelRoutes>
          </AdminRoutes> */}
          <Route path="/login" exact render={(props) => <Login {...props} />} />
          <Redirect from="*" to="/" />
        </Switch>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
    </>
  );
};

export const PanelRoutes = ({ children, ...props }) => {
  const mainContent = React.useRef(null);
  const [toggleSidebar, setToggleSidebar] = React.useState(false);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location?.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        toggleSidebar={toggleSidebar}
        setToggleSidebar={setToggleSidebar}
        logo={{
          innerLink: "/",
          imgSrc:
            "https://cdn.shopify.com/s/files/1/0613/9206/0577/files/moonshot-logo-transparent-bg-NOGLOW.png?v=1658316223&width=250",
          imgAlt: "...",
        }}
      />
      <div className="main-content ml-0" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location?.pathname)}
          toggleSidebar={toggleSidebar}
          setToggleSidebar={setToggleSidebar}
        />
        {children}
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};
