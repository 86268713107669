const timeZoneStr = "en-US";

export const getCurrencyInUSD = (price) => {
  const currency = +price;
  const formattedPrice = currency.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
  if (formattedPrice === "$0.00") {
    return "$0";
  }
  return formattedPrice;
};
