/*eslint-disable*/

// reactstrap components
import { TooltipWrapper } from "components/tooltip";
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";

const Footer = () => {
  return (
    <footer className="footer my-3 my-md-6">
      <Row className="align-items-center justify-content-xl-between px-2 px-sm-3">
        <Col xl="6">
          <div className="copyright text-center text-xl-left text-muted d-flex">
            © {new Date().getFullYear()}{" "}
            <a className="font-weight-bold ml-1">
              <TooltipWrapper
                id={"rwerwerwerwer"}
                textTwo="Moonshot Industries LLC"
              >
                Moonshot Industries LLC
              </TooltipWrapper>
            </a>
          </div>
        </Col>

        <Col xl="6">
          {/* <Nav className="nav-footer justify-content-center justify-content-xl-end">
            <NavItem>
              <NavLink>About Us</NavLink>
            </NavItem>
            <NavItem>
              <NavLink>Blog</NavLink>
            </NavItem>
          </Nav> */}
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
