export const defaultAdress = {
  name: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  instructions: "",
};

export const marketValues = [
  {
    name: "BNA",
    isChecked: false,
  },
  {
    name: "ATL",
    isChecked: false,
  },
  {
    name: "CHA",
    isChecked: false,
  },
  {
    name: "CKV",
    isChecked: false,
  },
];

export const displayOptionsShowArray = [
  {
    name: "UNASSIGNED",
  },
  {
    name: "Created",
  },
  {
    name: "ASSIGNED",
  },
  {
    name: "IN PROGRESS",
  },
  {
    name: "COMPLETED",
  },
  {
    name: "Cancelled",
  },
];

export const customNotifcArray = [
  {
    name: "Delivery Requested",
  },
  {
    name: "Driver Assigned",
  },
  {
    name: "Pickup 5min ETA",
  },
  {
    name: "Picked Up",
  },
  {
    name: "Pickup Exception",
  },
  {
    name: "Delivery 5min ETA",
  },
  {
    name: "Delivery Arrived",
  },
  {
    name: "Delivery Complete",
  },
  {
    name: "Delivery Exception",
  },
];

export const exportCheckboxArray = [
  {
    name: "Order ID",
    isChecked: true,
    dataKey: "order_source_ordername",
  },
  {
    name: "Placed",
    isChecked: true,
    dataKey: "order_placed_utc",
  },
  {
    name: "Cart",
    isChecked: true,
    dataKey: "order_amount_cart",
  },
  {
    name: "Del",
    isChecked: true,
    dataKey: "order_amount_shipping",
  },
  {
    name: "Tip",
    isChecked: true,
    dataKey: "mkt_tip",
  },
  {
    name: "Market",
    isChecked: true,
    dataKey: "msa_abbrev",
  },
  {
    name: "3PL Rate",
    isChecked: true,
    dataKey: "shipping_cost_estimated",
  },
  {
    name: "1st Delivery Provider",
    isChecked: true,
    dataKey: "localdelivery_driver_display_internal_name",
  },
];
export const displayOptionsShowFilterArray = [
  {
    name: "BNA",
    payloadKey: "BNA",
    isChecked: true,
  },
  {
    name: "ATL",
    payloadKey: "ATL",
    isChecked: true,
  },
  {
    name: "CHA",
    payloadKey: "CHA",
    isChecked: true,
  },
  {
    name: "CKV",
    payloadKey: "CKV",
    isChecked: true,
  },
  {
    name: "ICT",
    payloadKey: "ICT",
    isChecked: true,
  },
  {
    name: "N/A",
    payloadKey: "NULL",
    isChecked: true,
  },
];

export const bnaDeliveryProvider = [
  "3PL",
  "BNA-Chris",
  "BNA-Jason",
  "BNA-Ken",
  "BNA-Nic",
];
export const bnaSelectAddress = [
  {
    keyName: "Select Address",
    keyValue: "0",
  },
  {
    keyName: "935 Rev Dr Enoch Jones Blvd Nashville TN 37208",
    keyValue: "1",
  },
  {
    keyName: "3445 Lebanon Pike, Hermitage, TN 37076",
    keyValue: "2",
  },
  {
    keyName: "2300 Lebanon Pike, Nashville, TN 37214",
    keyValue: "3",
  },
];

export const atlDeliveryProvider = ["3PL", "ATL-Sal"];

export const atlSelectAddress = [
  {
    keyName: "Select Address",
    keyValue: "0",
  },
  {
    keyName: "2235 Cheshire Bridge Rd NE, Atlanta, GA 30324",
    keyValue: "1",
  },
  {
    keyName: "3735 Trickum Rd, Marietta, GA 30066, USA",
    keyValue: "2",
  },
];
export const chaDeliveryProvider = ["3PL"];

export const chaSelectAddress = [
  {
    keyName: "Select Address",
    keyValue: "0",
  },
  {
    keyName: "5764 TN-153, Hixson, TN 37343, USA",
    keyValue: "1",
  },
];

export const ictDeliveryProvider = ["3PL"];

export const ictSelectAddress = [
  {
    keyName: "Select Address",
    keyValue: "0",
  },
  {
    keyName: "7130 W Maple St, Wichita, KS 67209",
    keyValue: "1",
  },
  {
    keyName: "2250 N Rock Rd, Wichita, KS 67226",
    keyValue: "2",
  },
];
