import { TooltipWrapper } from "components/tooltip";
import React from "react";
import { Card, CardBody, CardText } from "reactstrap";

const PageTitle = ({ children }) => {
  return (
    <span className="h3 font-weight-bold px-0 px-md-2 mt-2 mb-3 d-flex justify-content-between">
      {children}
    </span>
  );
};

const PageCardComp = ({
  children,
  title,
  paddingTrue = false,
  pageCheckbox,
}) => {
  return (
    <Card className="w-100">
      <CardBody
        className="p-2 p-sm-3 p-lg-4"
        style={
          paddingTrue
            ? {
                padding: ".7rem",
              }
            : {}
        }
      >
        {title && (
          <PageTitle>
            <TooltipWrapper id={title + title + "werwerewr"} textTwo={title}>
              {title}
            </TooltipWrapper>
            {pageCheckbox}
          </PageTitle>
        )}
        <CardText>{children}</CardText>
      </CardBody>
    </Card>
  );
};

export default PageCardComp;
