import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const AdminRoutes = ({ component: Component, ...rest }) => {
  const isUserLogged = localStorage.getItem("isUserLogged");
  const history = useHistory();

  if (!isUserLogged) {
    history.push("/login");
  }

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} />
  );
};

export default AdminRoutes;
