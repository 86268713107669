import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

const InputStrap = ({
  label,
  labelChildren,
  name,
  onChange = () => {},
  value,
  type,
  min = null,
  required = null,
  className = "",
  selectArray,
  disabled = false,
  children,
  defaultValue,
}) => {
  return (
    <FormGroup>
      {labelChildren ? labelChildren : label ? <Label>{label}</Label> : ""}

      {type === "textarea" ? (
        <Input
          className={className}
          value={value}
          name={name}
          rows="3"
          type={type}
          disabled={disabled}
          onChange={onChange}
        />
      ) : type === "number" ? (
        <Input
          value={value}
          name={name}
          type={type}
          className={className}
          min={min}
          onChange={onChange}
          required={required}
          disabled={disabled}
        />
      ) : type === "select" ? (
        <Input
          name="select"
          type="select"
          className="form-control-alternative"
          style={{ color: "#000" }}
          disabled={disabled}
        >
          {children
            ? children
            : selectArray?.map((el) => (
                <option value={el.value}>{el.name}</option>
              ))}
        </Input>
      ) : (
        <Input
          value={value}
          defaultValue={defaultValue ? defaultValue : null}
          name={name}
          type="text"
          className={className}
          onChange={onChange}
          required={required}
          disabled={disabled}
        />
      )}
    </FormGroup>
  );
};

export default InputStrap;
