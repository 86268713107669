import React from "react";

const ToggleSwitchComp = ({
  name,
  handleChange = () => {},
  defaultChecked = true,
}) => {
  return (
    <div
      className="d-flex"
      id={name.replace("/ /g", "")}
      style={{ color: "#525f7f", fontWeight: "500" }}
    >
      <label className="custom-toggle mr-2">
        <input defaultChecked={defaultChecked} type="checkbox" />
        <span
          className="custom-toggle-slider rounded-circle"
          onClick={handleChange}
        />
      </label>
      {name}
    </div>
  );
};

export default ToggleSwitchComp;
