import axios from "axios";
import { checkUserSession } from "utils/date-methods";
import { clearLocalStorage } from "utils/local-storage-func";

// Create instance called http
const http = axios.create({
  baseURL: process.env.REACT_APP_LIVE_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

http.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "token"
)}`;

http.interceptors.request.use(
  function (config) {
    checkUserSession();
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// declare a response interceptor
http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // handle the response error
    if (error.response.status == 401) {
      clearLocalStorage();
      window.location.href = "/login";
    } else {
      return Promise.reject(error);
    }
  }
);
export default http;
