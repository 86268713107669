import Index from "views/Index.js";
import Maps from "views/examples/Maps.js";
import Delivery from "views/examples/delivery";
import Dispatcher from "views/examples/dispatcher";

var routes = [
  {
    path: "/",
    // name: "Dashboard",
    name: "Dispatcher",
    icon: "ni ni-tv-2 text-primary",
    // component: Index,
    component: Dispatcher,
    layout: "/",
  },
  // {
  //   path: "/delivery",
  //   name: "Delivery",
  //   icon: "ni ni-planet text-blue",
  //   component: Delivery,
  //   layout: "/",
  // },
  // {
  //   path: "/dispatcher",
  //   name: "Dispatcher",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Dispatcher,
  //   layout: "/",
  // },
];
export default routes;
