import React from "react";
import { UncontrolledAlert } from "reactstrap";

const AlertComp = ({ message }) => {
  return (
    <UncontrolledAlert color="danger" fade={false}>
      <span className="alert-inner--text font-weight-600">
        <strong>Error!</strong> {message}
      </span>
    </UncontrolledAlert>
  );
};

export default AlertComp;
