import http from "api";

export async function get_order_list_action(params, filteredTextArray) {
  return await http.post("shopify-filtered-orders", filteredTextArray, {
    params: { page: params || 1 },
  });
}

export async function get_order_by_id_action(id) {
  return await http.post("shopify-filtered-orders/" + id);
}

export async function xpress_create_shipment_action(data) {
  return await http.post("admin/xpress/create-shipment", data);
}

export async function dispatch_xpress_create_order_action(data) {
  return await http.post("admin/xpress/create-order", data);
}

export async function update_single_order_action(data) {
  return await http.post(
    "admin/shipment/update-delivery-address/" + data.id,
    data
  );
}
export async function dispatch_xpress_save_market_action(data) {
  return await http.post("admin/xpress/store-market", data);
}
