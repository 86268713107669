import React from "react";

const AddressTabs = ({
  id,
  bgColor,
  toggleState,
  tabNumber,
  onClick = () => {},
  name,
}) => {
  return (
    <a
      id={id}
      data-toggle="list"
      style={{
        border: "1px solid #e9ecef",
        backgroundColor: bgColor,
        cursor: "pointer",
      }}
      className={`list-group-item user-select-none font-weight-600 list-group-item-action curser-pointer text-center rounded-0 p-2 ${
        toggleState === tabNumber ? "active text-dark" : "text-white"
      }`}
      role="tab"
      onClick={onClick}
      aria-controls={"home" + tabNumber}
    >
      {name}
    </a>
  );
};

export default AddressTabs;
