import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Geocode from "react-geocode";

const GoogleInput = ({ formData, setFormData, placeholder }) => {
  const [address, setAddress] = useState(formData?.city);
  useEffect(() => {
    setAddress(
      formData?.address1
        ? formData?.address1 +
            ", " +
            formData?.city +
            ", " +
            formData?.state +
            ", " +
            formData?.zip
        : ""
    );
  }, [formData?.city, formData?.address1, formData?.zip]);

  const handleSelect = async (value) => {
    setAddress(value);
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);

    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
    Geocode.fromLatLng(latLng.lat, latLng.lng).then(
      (response) => {
        // const address = response.results[0].formatted_address;
        const addressArray = response.results[0].address_components;
        let zipCode, city, state, streetAddress;

        addressArray.forEach((component) => {
          // Extracting zip code
          if (component.types.includes("postal_code")) {
            zipCode = component.long_name;
          }
          // Extracting city
          if (component.types.includes("locality")) {
            city = component.long_name;
          }
          // Extracting state
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }

          if (
            component.types.includes("street_address") ||
            component.types.includes("route")
          ) {
            streetAddress = component.long_name;
          }
        });
        setFormData((prev) => ({
          ...prev,
          state: state,
          city: city,
          address1: streetAddress,
          zip: zipCode,
        }));
      },
      (error) => {
        console.error(error, "error");
      }
    );
  };
  return (
    <PlacesAutocomplete
      value={address}
      onChange={setAddress}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="auto-placeholder-main">
          <input
            className={"form-control mycustomeSearchbar"}
            {...getInputProps({})}
            placeholder={placeholder}
          />
          <div className="special">
            {suggestions.map((suggestion, ind) => {
              const style = {
                backgroundColor: suggestion.active ? "#39393a" : "#c50074",
                color: suggestion.active ? "white" : "white",
                padding: "8px",
                cursor: "pointer",
              };

              return (
                <div
                  key={ind}
                  {...getSuggestionItemProps(suggestion, { style })}
                >
                  {suggestion.description}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default GoogleInput;
