import React from "react";
import {
  get_order_by_id_action,
  xpress_create_shipment_action,
  get_order_list_action,
  dispatch_xpress_create_order_action,
  update_single_order_action,
  dispatch_xpress_save_market_action,
} from "actions/orderAction";
import { TooltipWrapper, TooltipComp } from "components/tooltip";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Form,
  Label,
  FormGroup,
  Input,
  Button,
  DropdownToggle,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import {
  getUserNameChecked,
  getZipCodeChecked,
  getOrderListTable,
  getUpdatedOrderListTable,
  setPreserveOrderListTable,
  clearAllData,
} from "redux/store/reducer/disptacher-reducer";
import Dots from "../../assets/img/ellipsis-vertical-solid.svg";
import Maps from "./Maps";
import { toastError, toastSuccess } from "utils/toast";
import AlertComp from "components/alerts";
import ModalComp from "components/modal";
import LoaderComp from "components/loader";
import PageCardComp from "components/page-card";
import ToggleSwitchComp from "components/toggle-switch";
import Pusher from "pusher-js";
import {
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from "utils/local-storage-func";
import { getCurrencyInUSD } from "utils/currency-methods";
import { getTimeGMT, getDateGMT } from "utils/date-methods";
import { useSortableData } from "hooks";
import AddressTabs from "components/controls/address-tabs";
import ButtonStrap from "components/controls/button-strap";

import { handleDownloadFun } from "utils/csv";
import { TooltipCheckboxComp } from "components/controls/checkbox";

import FulfilmentPickupForm from "components/fulfilment-pickup-form";
import {
  chaDeliveryProvider,
  chaSelectAddress,
  atlDeliveryProvider,
  atlSelectAddress,
  bnaDeliveryProvider,
  bnaSelectAddress,
  displayOptionsShowFilterArray as displayOptionsShowFilter,
  displayOptionsShowArray,
  customNotifcArray,
  exportCheckboxArray,
  defaultAdress,
  marketValues,
} from "constants";
import GoogleInput from "components/controls/google-input";
import { ictDeliveryProvider } from "constants";
import { ictSelectAddress } from "constants";

const CheckboxComp = ({
  checkboxValue = false,
  id,
  name,
  checkBoxName = "checkboxName",
  handleChange = () => {},
}) => {
  return (
    <div className="custom-checkbox ml-4 d-flex mb-0 mb-md-1">
      <input
        className="custom-control-input "
        id={id + name}
        style={{
          position: "static",
        }}
        defaultChecked={checkboxValue}
        onChange={handleChange}
        name={checkBoxName}
        type="checkbox"
      />
      <label className="custom-control-label" htmlFor={id + name}>
        <span style={{ left: "-20px" }}>{name && name}</span>
      </label>
    </div>
  );
};

const CheckboxWithCheckedComp = ({
  checkboxValue = false,
  id,
  name,
  checkBoxName = "checkboxName",
  handleChange = () => {},
}) => {
  return (
    <div
      className={`custom-checkbox ml-4 d-flex mb-0 mb-md-1 ${
        name === "Enabled" ? "mt-1 mt-sm-0" : ""
      }`}
    >
      <input
        className="custom-control-input "
        id={id + name}
        style={{
          position: "static",
        }}
        checked={checkboxValue}
        onChange={handleChange}
        name={checkBoxName}
        type="checkbox"
      />
      <label className="custom-control-label" htmlFor={id + name}>
        <span style={{ left: "-20px" }}>{name && name}</span>
      </label>
    </div>
  );
};

const RadioWithCheckedComp = ({
  checkboxValue = false,
  id,
  name,
  checkBoxName = "checkboxName",
  handleChange = () => {},
}) => {
  return (
    <div className={`d-flex align-items-center mb-0 mb-md-2}`}>
      <input
        className=" mr-1"
        id={id + name}
        style={{
          position: "static",
        }}
        checked={checkboxValue}
        onChange={handleChange}
        name={checkBoxName}
        type="radio"
      />
      <label className="ml-1 mt-2">
        <span>{name && name}</span>
      </label>
    </div>
  );
};

const EstimationDetailsRow = ({ text, value }) => (
  <Row className="w-100 d-flex flex-wrap m-0 mb-1 mb-sm-2">
    <Col sm={6} className="p-0 pr-2 font-weight-600">
      {text} :-
    </Col>
    <Col sm={6} className="p-0 pr-2 text-right">
      {value ? value : ""}
    </Col>
  </Row>
);

const Dispatcher = () => {
  const dispatch = useDispatch();

  const {
    isCustomerDeliveryZipChecked,
    isCustomerNameChecked,
    dataTableArray,
    preserveDataTableArray,
  } = useSelector((state) => state.dispatcher);
  const [orderFormData, setOrderFormData] = useState(null);
  const [exportCSVCheckboxArray, setExportCSVCheckboxArray] =
    useState(exportCheckboxArray);
  const [displayOptionsShowFilterArray, setDisplayOptionsShowFilterArray] =
    useState(displayOptionsShowFilter);

  const [bnaFormData, setBNAFormData] = useState(defaultAdress);
  const [atlFormData, setAtlFormData] = useState(defaultAdress);
  const [chaFormData, setChaFormData] = useState(defaultAdress);
  const [ictFormData, setIctFormData] = useState(defaultAdress);
  const [orderIdForMarket, setOrderIdForMarket] = useState(null);
  const [marketData, setMarketData] = useState(marketValues);
  const { dataList, requestSort, sortConfig } = useSortableData(dataTableArray);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isDelInfoModalOpen, setIsDelInfoModalOpen] = useState(false);
  const [isHamburgerModalOpen, setIsHamburgerModalOpen] = useState(false);
  const [isDetailOrderModalOpen, setIsDetailOrderModalOpen] = useState(false);
  const [isPriceDetailModalOpen, setIsPriceDetailModalOpen] = useState(false);
  const [isMapBoxHide, setIsMapBoxHide] = useState(false);
  const [isNotifBoxHide, setIsNotifBoxHide] = useState(!false);
  const [isPickupBoxHide, setIsPickupBoxHide] = useState(!false);
  const [isTableHide, setIsTableHide] = useState(!false);
  // const [isBnaFstChecked, setIsBnaFstChecked] = useState(!false);
  // const [isAtlFstChecked, setIsAtlFstChecked] = useState(!false);
  // const [isChaFstChecked, setIsChaFstChecked] = useState(!false);
  const [requoteLoading, setRequoteLoading] = useState(false);
  const [bnaEnableCheckBox, setBnaEnableCheckBox] = useState(false);
  const [atlEnableCheckBox, setAtlEnableCheckBox] = useState(false);
  const [chaEnableCheckBox, setChaEnableCheckBox] = useState(false);
  const [ictEnableCheckBox, setIctEnableCheckBox] = useState(false);
  const [atlOverrideDeliveryAdress, setAtlOverrideDeliveryAdress] =
    useState("");
  const [chaOverrideDeliveryAdress, setChaOverrideDeliveryAdress] =
    useState("");
  const [ictOverrideDeliveryAdress, setIctOverrideDeliveryAdress] =
    useState("");
  const [bnaOverrideDeliveryAdress, setBnaOverrideDeliveryAdress] =
    useState("");
  const [isBnaTabTrue, setIsBnaTabTrue] = useState(1);
  const [isAtlTabTrue, setIsAtlTabTrue] = useState(1);
  const [isChaTabTrue, setIsChaTabTrue] = useState(1);
  const [isIctTabTrue, setIsIctTabTrue] = useState(1);
  const [isBnaFDPTrue, setIsBnaFDPTrue] = useState("3PL");
  const [isAtlFDPTrue, setIsAtlFDPTrue] = useState("3PL");
  const [isChaFDPTrue, setIsChaFDPTrue] = useState("3PL");
  const [isIctFDPTrue, setIsIctFDPTrue] = useState("3PL");
  const [tabHighlighted, setTabHighlighted] = useState(1);
  const [requoteResult, setRequoteResults] = useState(null);
  const [requoteError, setRequoteErrors] = useState([]);
  const [dispatchResult, setDispatchResult] = useState([]);
  const [dispatchError, setDispatchErrors] = useState([]);
  const [orderRecordsPagination, setOrderRecordsPagination] = useState(null);
  const [pageLoader, setPageLoader] = useState(false);
  const [updateOrderLoader, setUpdateOrderLoader] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [orderDetailsId, setOrderDetailsId] = useState(null);
  const [orderDetailsData, setOrderDetailsData] = useState(null);
  const [priceDetailsData, setPriceDetailsData] = useState(null);

  let preserveDispatchedData = [];
  // * Function to handle option change
  const handleOptionChange = (e, orderNo) => {
    const selectedValue = e.target.value;

    setPageLoader((prev) => !prev);
    dispatch_xpress_save_market_action({
      market: selectedValue,
      orderno: orderNo,
    })
      .then(({ data }) => {
        if (data.data) {
          fetchOrderList(
            orderRecordsPagination?.currentPage,
            getUpdatedOrderListTable
          );
        } else if (!data.status) {
          toastError(data.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setPageLoader((prev) => !prev);
      });
  };

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const handlePriceClick = (data) => {
    const order_no = requoteResult
      ? Object.keys(requoteResult?.estimation_ids)
      : "";
    let order_data = null;
    if (requoteResult) {
      if (order_no[0] === data.order_source_ordername) {
        order_data =
          requoteResult?.estimation_ids[data.ordeorder_source_ordernamerNo]
            .data;
      } else {
        order_data = data;
      }
    } else {
      order_data = data;
    }
    let new_data = {
      provider: order_data?.shipping_provider_estimator
        ? order_data.shipping_provider_estimator
        : "",
      distance: order_data?.localdelivery_distance_miles_estimated
        ? order_data.localdelivery_distance_miles_estimated
        : order_data.distance
        ? order_data.distance
        : "",
      size: order_data?.package_type ? order_data.package_type : "SMALL",
      description: order_data?.shipping_estimate_description
        ? order_data.shipping_estimate_description
        : "",
      quantity: order_data?.shipping_estimate_number_of_items
        ? order_data?.shipping_estimate_number_of_items
        : "",
    };
    setPriceDetailsData(new_data);
  };

  useEffect(() => {
    fetchOrderList(1, getOrderListTable);

    const pusherInstance = new Pusher("7ad072a0ec1546f442b6", {
      cluster: "ap2",
      encrypted: true,
    });

    const channel = pusherInstance.subscribe("OrderCreatedNotificationTwo");
    channel.bind("Order-Created", (data) => {
      toastSuccess("New Order placed successfully");
      fetchOrderListonEvent(1);
    });

    return () => {
      removeStorageItem("currentPageOrderList");
      removeStorageItem("preservedOrderList");
      dispatch(clearAllData());
      pusherInstance.disconnect();
    };
  }, []);

  // window.addEventListener("beforeunload", function (e) {
  //   e.preventDefault();
  //   e.returnValue = "";
  //   console.log("User is trying to reload the page");
  // });

  useEffect(() => {
    orderDetailsId && handleOrderDetailFun(orderDetailsId);
  }, [orderDetailsId]);

  const handleOrderDetailFun = (id) => {
    setPageLoader((prev) => !prev);
    get_order_by_id_action(id)
      .then((res) => {
        if (res?.data?.success) {
          setOrderDetailsData(res?.data?.data);
          setIsDetailOrderModalOpen((prev) => !prev);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setPageLoader((prev) => !prev));
  };

  const handleDeliveryInformationFunc = (id) => {
    setPageLoader((prev) => !prev);
    get_order_by_id_action(id)
      .then((res) => {
        if (res?.data?.success) {
          const dataObj = res?.data?.data;
          setOrderFormData({
            id: dataObj.shipping_address?.address?.address_id,
            name:
              dataObj?.customer_first_name + " " + dataObj?.customer_last_name,
            address1: dataObj.shipping_address?.address?.street1,
            city: dataObj.shipping_address?.address?.city,
            state: dataObj.shipping_address?.address?.state,
            zip: dataObj.shipping_address?.address?.zip,
            phone: dataObj.customer_phone,
            delivery_instruction: dataObj?.delivery_instruction,
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setPageLoader((prev) => !prev));
  };

  useEffect(() => {
    orderId && handleDeliveryInformationFunc(orderId);
  }, [orderId]);

  const fetchOrderList = async (page, callbackFun) => {
    setPageLoader((prev) => !prev);
    preserveDispatchedData = joinArrayFunc();
    const filteredTextArray = displayOptionsShowFilterArray
      .filter((el) => el.isChecked)
      .map((el) => el.payloadKey);

    get_order_list_action(page, filteredTextArray)
      .then(({ data }) => {
        if (data?.success) {
          const orderDataList = data?.data.map((el) => {
            if (preserveDispatchedData.length) {
              const filterData = preserveDispatchedData.find(
                (elem) =>
                  elem.order_source_ordername === el.order_source_ordername
              );
              if (filterData?.order_source_ordername) {
                return { ...filterData, msa_abbrev: el.msa_abbrev };
              } else {
                return {
                  ...el,
                  isChecked: false,
                };
              }
            }
            return {
              ...el,
              isChecked: false,
              intOfr: "",
            };
          });
          delete data.data;
          delete data.query;
          delete data.message;
          setOrderRecordsPagination(data);
          setStorageItem("currentPageOrderList", JSON.stringify(orderDataList));
          dispatch(callbackFun(orderDataList));
        } else if (!data?.success) {
          if (data?.message === "No data found") {
            setStorageItem("currentPageOrderList", JSON.stringify([]));
            dispatch(callbackFun([]));
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setPageLoader((prev) => !prev);
      });
  };

  const fetchOrderListonEvent = async (page) => {
    setPageLoader((prev) => !prev);
    let currentPageOrderListOnEvent = [];

    const preservedOrderList = JSON.parse(getStorageItem("preservedOrderList"));
    const currentPageOrderList = JSON.parse(
      getStorageItem("currentPageOrderList")
    );

    if (currentPageOrderList?.length && preservedOrderList?.length) {
      const notExistElem = preservedOrderList
        .map((el) => {
          const checkOrderExist = currentPageOrderList.filter(
            (elem) => elem.order_source_ordername === el.order_source_ordername
          );
          if (checkOrderExist.length) return;
          return el;
        })
        .filter((el) => el !== undefined);

      const latestUpdated = currentPageOrderList
        ?.map((el) => {
          if (el.isChecked) return el;
        })
        .filter((el) => el !== undefined);

      currentPageOrderListOnEvent = [
        ...notExistElem,
        ...(latestUpdated.length ? latestUpdated : []),
      ];
    }

    if (currentPageOrderList?.length && !preservedOrderList?.length) {
      const preserveData = currentPageOrderList
        ?.map((el) => {
          if (el.isChecked) return el;
        })
        .filter((el) => el !== undefined);
      currentPageOrderListOnEvent = preserveData;
    }

    const filteredTextArray = displayOptionsShowFilterArray
      .filter((el) => el.isChecked)
      .map((el) => el.payloadKey);

    get_order_list_action(page, filteredTextArray)
      .then(({ data }) => {
        if (data?.success) {
          const orderDataList = data?.data.map((el) => {
            if (currentPageOrderListOnEvent.length) {
              const filterData = currentPageOrderListOnEvent.filter(
                (elem) =>
                  elem.order_source_ordername === el.order_source_ordername
              );
              if (filterData.length) {
                return filterData[0];
              } else {
                return {
                  ...el,
                  isChecked: false,
                  tip: "",
                };
              }
            }
            return {
              ...el,
              isChecked: false,
              tip: "",
              intOfr: "",
            };
          });
          setStorageItem("currentPageOrderList", JSON.stringify(orderDataList));
          dispatch(getOrderListTable(orderDataList));
          delete data.data;
          delete data.query;
          delete data.message;
          setOrderRecordsPagination(data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setPageLoader((prev) => !prev);
      });
  };

  useEffect(() => {
    setBNAFormData({
      ...bnaFormData,
      name:
        +isBnaTabTrue === 1
          ? "Ken"
          : +isBnaTabTrue === 2
          ? "Ken"
          : +isBnaTabTrue === 3
          ? "Nick"
          : "",
      address1:
        +isBnaTabTrue === 1
          ? "935 Rev Dr Enoch Jones Blvd"
          : +isBnaTabTrue === 2
          ? "3445 Lebanon Pike"
          : +isBnaTabTrue === 3
          ? "2300 Lebanon Pike"
          : "",
      city:
        +isBnaTabTrue === 1 || +isBnaTabTrue === 3
          ? "Nashville"
          : +isBnaTabTrue === 2
          ? "Hermitage"
          : "",
      state: +isBnaTabTrue !== 0 ? "TN" : "",
      zip:
        +isBnaTabTrue === 1
          ? "37208"
          : +isBnaTabTrue === 2
          ? "37076"
          : +isBnaTabTrue === 3
          ? "37214"
          : "",
      phone:
        +isBnaTabTrue === 1 || +isBnaTabTrue === 2
          ? "6154478467"
          : +isBnaTabTrue === 3
          ? "6155107632"
          : "",
      instructions:
        +isBnaTabTrue === 1
          ? "IMPORTANT:Use this address for GPS. It will take you to a parking lot with a black metal fence. Text when you get here and wait in your car, we'll be right out."
          : +isBnaTabTrue === 2
          ? "IMPORTANT: Meet the Ford Transit van (white) in the parking lot in front of the store."
          : +isBnaTabTrue === 3
          ? "IMPORTANT: Our driver is out delivering and handing this order off. Meet the Ford pickup (red) in the parking lot in front of the store."
          : "",
    });
  }, [isBnaTabTrue]);

  useEffect(() => {
    setAtlFormData({
      ...atlFormData,
      name: +isAtlTabTrue === 1 ? "Sal S" : +isAtlTabTrue === 2 ? "Sal" : "",
      address1:
        +isAtlTabTrue === 1
          ? "2235 Cheshire Bridge Rd NE"
          : +isAtlTabTrue === 2
          ? "3735 Trickum Rd"
          : "",
      city:
        +isAtlTabTrue === 1 ? "Atlanta" : +isAtlTabTrue === 2 ? "Marietta" : "",
      state: +isAtlTabTrue !== 0 ? "GA" : "",
      zip: +isAtlTabTrue === 1 ? "30324" : +isAtlTabTrue === 2 ? "30066" : "",
      phone: +isAtlTabTrue !== 0 ? "4049415342" : "",
      instructions:
        +isAtlTabTrue !== 0
          ? "IMPORTANT: Our driver is out delivering and will be handing off order to you. Meet in parking lot."
          : "",
    });
  }, [isAtlTabTrue]);

  useEffect(() => {
    setChaFormData({
      ...chaFormData,
      name: +isChaTabTrue === 1 ? "Taylor" : "",
      address1: +isChaTabTrue === 1 ? "5764 TN-153" : "",
      city: +isChaTabTrue === 1 ? "Hixson" : "",
      state: +isChaTabTrue !== 0 ? "TN" : "",
      zip: +isChaTabTrue === 1 ? "37343" : "",
      phone: +isChaTabTrue === 1 ? "14234010186" : "",
      instructions:
        +isChaTabTrue === 1
          ? "Our driver is out delivering and handing this one off. Meet in parking lot."
          : "",
    });
  }, [isChaTabTrue]);

  useEffect(() => {
    setIctFormData({
      ...ictFormData,
      name: +isIctTabTrue !== 0 ? "Miranda" : "",
      address1:
        +isIctTabTrue === 1
          ? "7130 W Maple St"
          : +isIctTabTrue === 2
          ? "2250 N Rock Rd"
          : "",
      city: +isIctTabTrue !== 0 ? "Wichita" : "",
      state: +isIctTabTrue !== 0 ? "KS" : "",
      zip: +isIctTabTrue === 1 ? "67209" : +isIctTabTrue === 2 ? "67226" : "",
      phone: +isIctTabTrue !== 0 ? "3162159045" : "",
      instructions:
        +isIctTabTrue !== 0
          ? "Come inside the store and tell them you have a pickup order."
          : "",
    });
  }, [isIctTabTrue]);

  useEffect(() => {
    fetchOrderList(1, getOrderListTable);
  }, [displayOptionsShowFilterArray]);

  const handleToggleChange = (fun) => {
    fun((prev) => !prev);
  };

  const handleTableCheckboxClick = (id) => {
    const updatedData = dataTableArray.map((data) => {
      if (data.order_source_ordername === id) {
        return {
          ...data,
          isChecked: !data.isChecked,
        };
      }
      return data;
    });
    setStorageItem("currentPageOrderList", JSON.stringify(updatedData));
    dispatch(getOrderListTable(updatedData));
  };

  const handleCheckBoxSaveClick = (e) => {
    e.preventDefault();
    const updatedData = dataTableArray.map((el) => {
      if (el.order_source_ordername === orderIdForMarket) {
        return {
          ...el,
          assignedMarket: marketData,
        };
      } else {
        return el;
      }
    });
    const market = marketData.find((m) => {
      if (m.isChecked) {
        return m;
      }
    });
    dispatch_xpress_save_market_action({
      market: market.name,
      orderno: orderIdForMarket,
    });
    setIsModalOpen((prev) => !prev);
    setMarketData(marketValues);

    setStorageItem("currentPageOrderList", JSON.stringify(updatedData));
    dispatch(getOrderListTable(updatedData));
    // setOrderListData(updatedData);
  };

  const handleCheckBoxSaveChange = (ind) => {
    const data = marketData.map((data, index) => {
      if (index === ind) {
        return {
          ...data,
          isChecked: !data.isChecked,
        };
      } else {
        return {
          ...data,
          isChecked: false,
        };
      }
    });
    setMarketData(data);
  };

  const handleOrderDataChange = (e, callbackFun) => {
    const { name, value } = e.target;
    callbackFun((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleMarketTipChange = (e, id, key) => {
    const { value } = e.target;
    const filteredListData = dataTableArray.map((el) => {
      if (el.order_source_ordername === id) {
        return {
          ...el,
          [key]: value.replace("-", ""),
        };
      } else {
        return el;
      }
    });
    setStorageItem("currentPageOrderList", JSON.stringify(filteredListData));
    dispatch(getOrderListTable(filteredListData));
  };

  const handleRequoteClick = async (e) => {
    e.preventDefault();

    const marketObjAtl = {
      pickup_street_1: atlFormData.address1,
      pickup_city: atlFormData.city,
      pickup_state: atlFormData.state,
      pickup_zip: atlFormData.zip,
    };
    const marketObjBna = {
      pickup_street_1: bnaFormData.address1,
      pickup_city: bnaFormData.city,
      pickup_state: bnaFormData.state,
      pickup_zip: bnaFormData.zip,
    };

    const marketObjCha = {
      pickup_street_1: chaFormData.address1,
      pickup_city: chaFormData.city,
      pickup_state: chaFormData.state,
      pickup_zip: chaFormData.zip,
    };

    const marketObjIct = {
      pickup_street_1: ictFormData.address1,
      pickup_city: ictFormData.city,
      pickup_state: ictFormData.state,
      pickup_zip: ictFormData.zip,
    };

    let dispatchArray = new Array();
    if (dataTableArray?.length && preserveDataTableArray?.length) {
      const notExistElem = preserveDataTableArray
        .map((el) => {
          const checkOrderExist = dataTableArray.filter(
            (elem) => elem.order_source_ordername === el.order_source_ordername
          );
          if (checkOrderExist.length) return;
          return el;
        })
        .filter((el) => el !== undefined);

      const sameElem = preserveDataTableArray
        .map((el) => {
          const checkOrderExist = dataTableArray.filter(
            (elem) => elem.order_source_ordername === el.order_source_ordername
          );
          if (checkOrderExist.length) return checkOrderExist[0];
        })
        .filter((el) => el !== undefined);

      const latestUpdated = dataTableArray
        ?.map((el) => {
          if (el.isChecked) return el;
        })
        .filter((el) => el !== undefined);

      const sameUpdatedElem = latestUpdated
        .map((el) => {
          const checkOrderExist = sameElem.filter(
            (elem) => elem.order_source_ordername === el.order_source_ordername
          );
          if (checkOrderExist.length) return checkOrderExist[0];
        })
        .filter((el) => el !== undefined);

      dispatchArray = [
        ...notExistElem,
        ...(sameUpdatedElem.length ? sameUpdatedElem : latestUpdated),
      ];
    }

    if (dataTableArray?.length && !preserveDataTableArray?.length) {
      const preserveData = dataTableArray
        ?.map((el) => {
          if (el.isChecked) return el;
        })
        .filter((el) => el !== undefined);
      dispatchArray = preserveData;
    }
    const isMarketChecked = dispatchArray
      ?.map((data) => {
        return data.isChecked ? data : undefined;
      })
      .filter((el) => el !== undefined);

    const isBNAMarketChecked = isMarketChecked.find(
      (data) => data?.msa_abbrev?.toUpperCase() === "BNA"
    );
    const isMarketNotChecked = isMarketChecked.every(
      (data) => data?.msa_abbrev
    );
    const isATLMarketChecked = isMarketChecked.find(
      (data) => data?.msa_abbrev?.toUpperCase() === "ATL"
    );
    const isCHAMarketChecked = isMarketChecked.find(
      (data) => data?.msa_abbrev?.toUpperCase() === "CHA"
    );
    const isIctMarketChecked = isMarketChecked.find(
      (data) => data?.msa_abbrev?.toUpperCase() === "ICT"
    );

    if (!isMarketChecked?.length) {
      toastError("Please select a row to Proceed");
    } else if (isMarketChecked.length && !isMarketNotChecked) {
      toastError("Select a market from selected rows");
    } else if (
      !+isBnaTabTrue &&
      isBNAMarketChecked?.msa_abbrev &&
      !+isAtlTabTrue &&
      isATLMarketChecked?.msa_abbrev &&
      !+isChaTabTrue &&
      isCHAMarketChecked?.msa_abbrev &&
      !+isIctTabTrue &&
      isIctMarketChecked?.msa_abbrev
    ) {
      toastError("Please select addresses for selected markets");
    } else if (!+isBnaTabTrue && isBNAMarketChecked?.msa_abbrev) {
      toastError("Please select an address of BNA market");
    } else if (!+isAtlTabTrue && isATLMarketChecked?.msa_abbrev) {
      toastError("Please select an address of ATL market");
    } else if (!+isChaTabTrue && isCHAMarketChecked?.msa_abbrev) {
      toastError("Please select an address of CHA market");
    } else if (!+isIctTabTrue && isIctMarketChecked?.msa_abbrev) {
      toastError("Please select an address of ICT market");
    } else {
      const apiPayload = isMarketChecked.map((el) => {
        // requote payload
        return {
          delivery_city: el.shipping_address.address.city,
          delivery_country: "US",
          delivery_countryDF: "US",
          delivery_state: el.shipping_address.address.state,
          delivery_street_1: el.shipping_address.address.street1,
          delivery_zip: el.shipping_address.address.zip,
          description: "OTHERS",
          market: el.msa_abbrev,
          mkt_tip: +el.mkt_tip,
          shipping_type: el.shipping_type_requested,
          order_no: el.order_source_ordername,
          order_value: 500,
          package_type: "SMALL",
          pickup_country: "US",

          pickup_city:
            el.msa_abbrev?.toUpperCase() === "BNA"
              ? marketObjBna.pickup_city
              : el.msa_abbrev?.toUpperCase() === "ATL"
              ? marketObjAtl.pickup_city
              : el.msa_abbrev?.toUpperCase() === "CHA"
              ? marketObjCha.pickup_city
              : el.msa_abbrev?.toUpperCase() === "ICT"
              ? marketObjIct.pickup_city
              : "",
          pickup_state:
            el.msa_abbrev?.toUpperCase() === "BNA"
              ? marketObjBna.pickup_state
              : el.msa_abbrev?.toUpperCase() === "ATL"
              ? marketObjAtl.pickup_state
              : el.msa_abbrev?.toUpperCase() === "CHA"
              ? marketObjCha.pickup_state
              : el.msa_abbrev?.toUpperCase() === "ICT"
              ? marketObjIct.pickup_state
              : "",
          pickup_street_1:
            el.msa_abbrev?.toUpperCase() === "BNA"
              ? marketObjBna.pickup_street_1
              : el.msa_abbrev?.toUpperCase() === "ATL"
              ? marketObjAtl.pickup_street_1
              : el.msa_abbrev?.toUpperCase() === "CHA"
              ? marketObjCha.pickup_street_1
              : el.msa_abbrev?.toUpperCase() === "ICT"
              ? marketObjIct.pickup_street_1
              : "",
          pickup_zip:
            el.msa_abbrev?.toUpperCase() === "BNA"
              ? marketObjBna.pickup_zip
              : el.msa_abbrev?.toUpperCase() === "ATL"
              ? marketObjAtl.pickup_zip
              : el.msa_abbrev?.toUpperCase() === "CHA"
              ? marketObjCha.pickup_zip
              : el.msa_abbrev?.toUpperCase() === "ICT"
              ? marketObjIct.pickup_zip
              : "",
        };
      });
      setRequoteLoading((prev) => !prev);
      setRequoteErrors([]);

      xpress_create_shipment_action(apiPayload)
        .then(({ data }) => {
          if (data?.status) {
            if (data.data.estimation_ids) {
              Object.keys(data.data.estimation_ids).map((elem) => {
                toastSuccess(
                  `${elem} New 3PL Delivery Price ${getCurrencyInUSD(
                    +data.data.estimation_ids[elem]?.data?.price
                  )}.`
                );
              });
              if (requoteResult?.estimation_ids) {
                let abc = Object.keys(requoteResult?.estimation_ids);
                const reqRes = abc.filter(
                  (elem) => !data.data.estimation_ids?.hasOwnProperty(elem)
                );
                if (reqRes.length) {
                  const calcData = reqRes.map((el) => {
                    data.data.estimation_ids[el] =
                      requoteResult?.estimation_ids[el];
                  });
                  setRequoteResults(data.data);
                } else {
                  setRequoteResults(data.data);
                }
              } else {
                setRequoteResults(data.data);
              }
            }
            if (data?.errors?.length) {
              setRequoteErrors(data.errors);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }
            toastSuccess(data?.estimation_ids?.message);
          } else {
            toastError("Selected market estimation is not available");
          }
        })
        .catch((err) => {
          toastError("Internal server error");
          console.log(err);
        })
        .finally(() => {
          setRequoteLoading((prev) => !prev);
        });
    }
  };

  const handleDispatchSelectedOrderClick = (e) => {
    e.preventDefault();
    const marketObjAtl = {
      pickup_phone: atlFormData.phone,
      pickup_instruction: atlFormData.instructions,
      name: atlFormData.name,
    };

    const marketObjBna = {
      pickup_phone: bnaFormData.phone,
      pickup_instruction: bnaFormData.instructions,
      name: bnaFormData.name,
    };
    const marketObjCha = {
      pickup_phone: chaFormData.phone,
      pickup_instruction: chaFormData.instructions,
      name: chaFormData.name,
    };
    const marketObjIct = {
      pickup_phone: ictFormData.phone,
      pickup_instruction: ictFormData.instructions,
      name: ictFormData.name,
    };
    let dispatchArray = new Array();

    if (dataTableArray?.length && preserveDataTableArray?.length) {
      const notExistElem = preserveDataTableArray
        .map((el) => {
          const checkOrderExist = dataTableArray.filter(
            (elem) => elem.order_source_ordername === el.order_source_ordername
          );
          if (checkOrderExist.length) return;
          return el;
        })
        .filter((el) => el !== undefined);

      const sameElem = preserveDataTableArray
        .map((el) => {
          const checkOrderExist = dataTableArray.filter(
            (elem) => elem.order_source_ordername === el.order_source_ordername
          );
          if (checkOrderExist.length) return checkOrderExist[0];
        })
        .filter((el) => el !== undefined);

      const latestUpdated = dataTableArray
        ?.map((el) => {
          if (el.isChecked) return el;
        })
        .filter((el) => el !== undefined);

      const sameUpdatedElem = latestUpdated
        .map((el) => {
          const checkOrderExist = sameElem.filter(
            (elem) => elem.order_source_ordername === el.order_source_ordername
          );
          if (checkOrderExist.length) return checkOrderExist[0];
        })
        .filter((el) => el !== undefined);

      dispatchArray = [
        ...notExistElem,
        ...(sameUpdatedElem.length ? sameUpdatedElem : latestUpdated),
      ];
    }
    if (dataTableArray?.length && !preserveDataTableArray?.length) {
      const preserveData = dataTableArray
        ?.map((el) => {
          if (el.isChecked) return el;
        })
        .filter((el) => el !== undefined);
      dispatchArray = preserveData;
    }

    const isMarketChecked = dispatchArray
      ?.map((data) => {
        return data?.isChecked ? data : undefined;
      })
      .filter((el) => el !== undefined);
    //
    const isMarketNotChecked = isMarketChecked.every(
      (data) => data?.msa_abbrev
    );
    const isBNAMarketChecked = isMarketChecked?.find(
      (data) => data?.msa_abbrev?.toUpperCase() === "BNA"
    );
    const isATLMarketChecked = isMarketChecked?.find(
      (data) => data?.msa_abbrev?.toUpperCase() === "ATL"
    );
    const isCHAMarketChecked = isMarketChecked?.find(
      (data) => data?.msa_abbrev?.toUpperCase() === "CHA"
    );
    const isIctMarketChecked = isMarketChecked?.find(
      (data) => data?.msa_abbrev?.toUpperCase() === "ICT"
    );
    if (!isMarketChecked?.length) {
      toastError("Please select a row to Proceed");
    } else if (!isMarketNotChecked && isMarketChecked?.length) {
      toastError("Select a market from selected rows");
    } else if (
      !+isAtlTabTrue &&
      isATLMarketChecked?.msa_abbrev &&
      !+isAtlTabTrue &&
      isATLMarketChecked?.msa_abbrev &&
      !+isChaTabTrue &&
      isCHAMarketChecked?.msa_abbrev &&
      !+isIctTabTrue &&
      isIctMarketChecked?.msa_abbrev
    ) {
      toastError("Please select addresses for selected markets");
    } else if (!+isBnaTabTrue && isBNAMarketChecked?.msa_abbrev) {
      toastError("Please select an address of BNA market");
    } else if (!+isAtlTabTrue && isATLMarketChecked?.msa_abbrev) {
      toastError("Please select an address of ATL market");
    } else if (!+isChaTabTrue && isCHAMarketChecked?.msa_abbrev) {
      toastError("Please select an address of CHA market");
    } else if (!+isIctTabTrue && isIctMarketChecked?.msa_abbrev) {
      toastError("Please select an address of ICT market");
    } else {
      // dispatcher payload
      const apiPayload = isMarketChecked.map((el) => {
        return {
          delivery_email: "",
          delivery_instruction:
            el.msa_abbrev?.toUpperCase() === "BNA"
              ? bnaEnableCheckBox
                ? bnaOverrideDeliveryAdress
                : ""
              : el.msa_abbrev?.toUpperCase() === "ATL"
              ? atlEnableCheckBox
                ? atlOverrideDeliveryAdress
                : ""
              : el.msa_abbrev?.toUpperCase() === "CHA"
              ? chaEnableCheckBox
                ? chaOverrideDeliveryAdress
                : ""
              : el.msa_abbrev?.toUpperCase() === "ICT"
              ? ictEnableCheckBox
                ? ictOverrideDeliveryAdress
                : ""
              : "",
          delivery_name: el.customer_first_name + " " + el.customer_last_name,
          delivery_phone: el.customer_phone,
          estimation_id: requoteResult?.estimation_ids?.hasOwnProperty(
            el.order_source_ordername
          )
            ? requoteResult?.estimation_ids[el.order_source_ordername].data?.id
            : el?.shipping_estimation_id,
          market: el.msa_abbrev,
          order_id: el.order_source_uuid,
          pickup_email: "",
          pickup_instruction:
            el.msa_abbrev?.toUpperCase() === "BNA"
              ? marketObjBna.pickup_instruction
              : el.msa_abbrev?.toUpperCase() === "ATL"
              ? marketObjAtl.pickup_instruction
              : el.msa_abbrev?.toUpperCase() === "CHA"
              ? marketObjCha.pickup_instruction
              : el.msa_abbrev?.toUpperCase() === "ICT"
              ? marketObjIct.pickup_instruction
              : "",
          pickup_name:
            el.msa_abbrev?.toUpperCase() === "BNA"
              ? marketObjBna.name
              : el.msa_abbrev?.toUpperCase() === "ATL"
              ? marketObjAtl.name
              : el.msa_abbrev?.toUpperCase() === "CHA"
              ? marketObjCha.name
              : el.msa_abbrev?.toUpperCase() === "ICT"
              ? marketObjIct.name
              : "",

          pickup_phone:
            el.msa_abbrev?.toUpperCase() === "BNA"
              ? marketObjBna.pickup_phone
              : el.msa_abbrev?.toUpperCase() === "ATL"
              ? marketObjAtl.pickup_phone
              : el.msa_abbrev?.toUpperCase() === "CHA"
              ? marketObjCha.pickup_phone
              : el.msa_abbrev?.toUpperCase() === "ICT"
              ? marketObjIct.pickup_phone
              : "",

          first_delivery_provider:
            el.msa_abbrev?.toUpperCase() === "BNA"
              ? isBnaFDPTrue
              : el.msa_abbrev?.toUpperCase() === "ATL"
              ? isAtlFDPTrue
              : el.msa_abbrev?.toUpperCase() === "CHA"
              ? isChaFDPTrue
              : el.msa_abbrev?.toUpperCase() === "ICT"
              ? isIctFDPTrue
              : "",
          mkt_tip: +el.mkt_tip,
          orderNo: el?.order_source_ordername,
        };
      });
      setDispatchErrors([]);
      setRequoteLoading((prev) => !prev);
      dispatch_xpress_create_order_action(apiPayload)
        .then(({ data }) => {
          if (data?.data?.order_ids) {
            toastSuccess("Order saved successfully");

            if (dispatchResult?.order_ids) {
              let abc = Object.keys(dispatchResult?.order_ids);
              const reqRes = abc.filter(
                (elem) => !data.data.order_ids?.hasOwnProperty(elem)
              );
              if (reqRes.length) {
                const calcData = reqRes.map((el) => {
                  data.data.order_ids[el] = dispatchResult?.order_ids[el];
                });
                setDispatchResult(data.data);
              } else {
                setDispatchResult(data.data);
              }
            } else {
              setDispatchResult(data.data);
            }
          }
          if (data?.errors.length) {
            setDispatchErrors(data?.errors);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }
        })
        .catch((err) => {
          toastError("Internal server error");
          console.log(err);
        })
        .finally(() => {
          setRequoteLoading((prev) => !prev);
        });
    }
  };

  const handleSingleOrderSubmit = (e) => {
    e.preventDefault();
    setUpdateOrderLoader((prev) => !prev);
    update_single_order_action(orderFormData)
      .then(({ data }) => {
        if (+data.code === 400 && data.errors.length) {
          data.errors.map((err) => toastError(err));
        } else {
          setOrderId(null);
          setOrderFormData(null);
          setIsDelInfoModalOpen(false);
          toastSuccess("Record updated successfully");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setUpdateOrderLoader((prev) => !prev));
  };

  const handleCSVModalClose = () => {
    setExportCSVCheckboxArray(exportCheckboxArray);
    setIsExportModalOpen((prev) => !prev);
  };

  const joinArrayFunc = () => {
    const preservedOrderList = JSON.parse(getStorageItem("preservedOrderList"));

    if (dataTableArray?.length && preservedOrderList?.length) {
      const notExistElem = preservedOrderList
        .map((el) => {
          const checkOrderExist = dataTableArray.filter(
            (elem) => elem.order_source_ordername === el.order_source_ordername
          );
          if (checkOrderExist.length) return;
          return el;
        })
        .filter((el) => el !== undefined);

      const latestUpdated = dataTableArray
        ?.map((el) => {
          if (el.isChecked) return el;
        })
        .filter((el) => el !== undefined);
      let concatenatedArray = [
        ...notExistElem,
        ...(latestUpdated?.length ? latestUpdated : []),
      ];
      setStorageItem("preservedOrderList", JSON.stringify(concatenatedArray));
      dispatch(setPreserveOrderListTable(concatenatedArray));
      return concatenatedArray;
    }
    if (!dataTableArray?.length) return [];
    if (dataTableArray?.length && !preservedOrderList?.length) {
      const preserveData = dataTableArray
        ?.map((el) => {
          if (el.isChecked) return el;
        })
        .filter((el) => el !== undefined);
      setStorageItem("preservedOrderList", JSON.stringify(preserveData));
      dispatch(setPreserveOrderListTable(preserveData));
      return preserveData;
    }
  };

  const handleCsvDownloadFun = () => {
    const csvArray = joinArrayFunc();
    if (!exportCSVCheckboxArray.some((el) => el.isChecked)) {
      toastError("Please check at least one checkbox");
    } else if (!csvArray?.length) {
      toastError("Please check an item to export csv file");
    } else if (csvArray?.length) {
      handleDownloadFun(
        csvArray,
        exportCSVCheckboxArray,
        dispatchResult,
        requoteResult,
        handleCSVModalClose
      );
    }
  };

  const handleExportCheckboxChanged = (ind) => {
    const checkboxData = exportCSVCheckboxArray.map((el, index) => {
      if (index === ind) {
        return {
          ...el,
          isChecked: !el.isChecked,
        };
      } else {
        return el;
      }
    });
    setExportCSVCheckboxArray(checkboxData);
  };

  const handleFilteredMarketDataFun = (ind) => {
    const data = displayOptionsShowFilterArray.map((el, index) => {
      if (index === ind) {
        return {
          ...el,
          isChecked: !el.isChecked,
        };
      }
      return el;
    });
    setDisplayOptionsShowFilterArray(data);
  };

  return (
    <>
      <div className="header bg-gradient-secondaryone pt-7"></div>
      <Container className="" fluid>
        <Row className="d-flex flex-column mt-3 mt-md-5">
          {requoteError?.map((error) => (
            <Col key={error?.message} className="p-0 px-3">
              <AlertComp message={error.message} />
            </Col>
          ))}
          {dispatchError?.map((error, ind) => (
            <Col key={error + ind} className="p-0 px-3">
              <AlertComp message={error} />
            </Col>
          ))}
        </Row>
        <Row>
          <div className="col pt-6 p-0">
            <Row className="d-flex flex-column px-3">
              <Col className="p-0">
                <PageCardComp
                  title="Map"
                  pageCheckbox={
                    <div id="app-title2">
                      <ToggleSwitchComp
                        name="Show"
                        defaultChecked={false}
                        handleChange={() => handleToggleChange(setIsMapBoxHide)}
                      />

                      <TooltipComp
                        textOne={"Select"}
                        textTwo={"Unselect"}
                        anchorId="app-title2"
                        varName={!isMapBoxHide}
                      />
                    </div>
                  }
                  paddingTrue={true}
                >
                  <div
                    className={`w-100 ${
                      isMapBoxHide ? "custom_transitions" : "d-none"
                    }`}
                  >
                    <Maps />
                  </div>
                </PageCardComp>
              </Col>
            </Row>

            <Row className="d-flex flex-column mt-4 mt-md-6">
              <Col className="p-0 px-3">
                <Card className="shadow">
                  <CardHeader className="border-0 d-flex justify-content-between">
                    <div className="d-flex align-items-center navbar-light">
                      {isTableHide && (
                        <TooltipWrapper
                          id={"titleOrcdecrtswerwwewererewr"}
                          textTwo="Add/Remove Columns"
                        >
                          <button
                            className="navbar-toggler pl-0"
                            type="button"
                            onClick={() =>
                              setIsHamburgerModalOpen((prev) => !prev)
                            }
                          >
                            <span className="navbar-toggler-icon" />
                          </button>
                        </TooltipWrapper>
                      )}
                      <TooltipWrapper
                        id={"titleOrcdecrtswerwerewr"}
                        textTwo="Orders"
                      >
                        <h3 className="mb-0">Orders</h3>
                      </TooltipWrapper>
                    </div>
                    <div id="app-title1">
                      <ToggleSwitchComp
                        name="Show"
                        handleChange={() => handleToggleChange(setIsTableHide)}
                      />
                      <TooltipComp
                        textOne={"Select"}
                        textTwo={"Unselect"}
                        anchorId="app-title1"
                        varName={!isTableHide}
                      />
                    </div>
                  </CardHeader>
                  <Row
                    className={`icon-examples custom_transitions my-3 flex-wrap ${
                      isTableHide ? "d-flex" : "d-none"
                    }`}
                  >
                    {displayOptionsShowArray.map((el, ind) => (
                      <Col
                        sm={6}
                        xl={3}
                        key={ind}
                        className="pl-5 pl-md-5 mb-1 mb-md-3"
                      >
                        <CheckboxComp id={ind} name={el.name.toUpperCase()} />
                      </Col>
                    ))}
                  </Row>

                  <Row className="icon-examples custom_transitions my-3 flex-wrap d-flex">
                    {displayOptionsShowFilterArray.map((el, ind) => (
                      <Col
                        sm={6}
                        xl={3}
                        key={ind}
                        className="pl-5 pl-md-5 mb-1 mb-md-3"
                      >
                        <TooltipCheckboxComp
                          id={ind}
                          name={el.name.toUpperCase()}
                          checkBoxName={el.name.toUpperCase()}
                          checkboxValue={el.isChecked}
                          handleChange={() => handleFilteredMarketDataFun(ind)}
                        />
                      </Col>
                    ))}
                  </Row>

                  <Table
                    className={`align-items-center flex-column table-flush custom_transitions dispatcher_table ${
                      isTableHide ? "" : "d-none"
                    }`}
                    responsive
                  >
                    <thead className="thead-light">
                      <tr
                        style={{
                          position: "relative",
                        }}
                      >
                        <th
                          className="table_padding text-uppercase"
                          style={{
                            background: "#f6f9fc",
                          }}
                          scope="col"
                        >
                          Select
                        </th>
                        <th
                          className="table_padding"
                          style={{
                            background: "#f6f9fc",
                          }}
                          scope="col"
                        >
                          <button
                            type="button"
                            onClick={() =>
                              requestSort("order_source_ordername")
                            }
                            className={
                              getClassNamesFor("order_source_ordername") +
                              " text-uppercase"
                            }
                          >
                            Order
                          </button>
                        </th>

                        <th
                          scope="col"
                          className={
                            isCustomerNameChecked
                              ? "table_padding text-uppercase"
                              : "d-none"
                          }
                        >
                          Customer Name
                        </th>

                        {isCustomerDeliveryZipChecked && (
                          <th
                            className="table_padding text-uppercase"
                            scope="col"
                          >
                            Delivery Zip Code
                          </th>
                        )}

                        <th
                          className="table_padding"
                          style={{
                            background: "#f6f9fc",
                          }}
                          scope="col"
                        >
                          <button
                            type="button"
                            onClick={() => requestSort("order_placed_stc")}
                            className={
                              getClassNamesFor("order_placed_stc") +
                              " text-uppercase"
                            }
                          >
                            Placed
                          </button>
                        </th>
                        <th className="table_padding" scope="col">
                          <button
                            type="button"
                            onClick={() => requestSort("order_amount_cart")}
                            className={
                              getClassNamesFor("order_amount_cart") +
                              " text-uppercase"
                            }
                          >
                            Cart $
                          </button>
                        </th>
                        <th
                          className="table_padding text-uppercase"
                          scope="col"
                        >
                          Del+Tip
                        </th>
                        <th
                          className="table_padding text-uppercase"
                          style={{
                            background: "#f6f9fc",
                          }}
                          scope="col"
                        >
                          3PL
                        </th>
                        <th className="table_padding" scope="col px-4">
                          Mkt Tip
                        </th>
                        <th className="table_padding" scope="col">
                          Int Offer
                        </th>
                        <th className="table_padding" scope="col">
                          MSA
                        </th>
                        <th className="table_padding" scope="col">
                          Delivery Info
                        </th>
                        <th className="table_padding" scope="col">
                          Delivery Status
                        </th>
                        <th className="table_padding" scope="col">
                          More
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataList?.map((data, ind) => {
                        const todayDate = new Date();
                        const todayDateString = `${
                          todayDate.getMonth() + 1 > 9
                            ? todayDate.getMonth() + 1
                            : "0" +
                              (+todayDate.getMonth() + 1) +
                              "/" +
                              todayDate.getDate() +
                              "/" +
                              todayDate.getFullYear()
                        }`;
                        const orderDate = new Date(data.createdon);
                        const orderDateString = `${
                          orderDate.getMonth() + 1 > 9
                            ? orderDate.getMonth() + 1
                            : "0" +
                              (+orderDate.getMonth() + 1) +
                              "/" +
                              orderDate.getDate() +
                              "/" +
                              orderDate.getFullYear()
                        }`;
                        const checkStatus = (id) => {
                          window.open(
                            process.env.REACT_APP_STATUS_BASE_URL + id,
                            "_blank"
                          );
                        };
                        return (
                          <tr
                            key={
                              data?.order_source_ordername +
                              data?.orders_main_id +
                              ind
                            }
                            style={{
                              background: data.isChecked
                                ? "#eeeeee"
                                : "transparent",
                            }}
                          >
                            <th
                              scope="row"
                              style={{
                                background: data.isChecked ? "#eeeeee" : "#fff",
                                width: "77px",
                              }}
                              className="pr-2 px-lg-2 px-xl-3 py-1 pl-0 pl-lg-1"
                            >
                              <CheckboxWithCheckedComp
                                id={`${data?.order_source_ordername}`}
                                checkboxValue={data.isChecked}
                                handleChange={() =>
                                  handleTableCheckboxClick(
                                    data.order_source_ordername
                                  )
                                }
                              />
                            </th>
                            <th
                              className="p-0"
                              style={{
                                background: data.isChecked ? "#eeeeee" : "#fff",
                                width: "104px",
                              }}
                            >
                              <strong
                                className="px-1 px-lg-2 d-flex align-items-center px-xl-3"
                                style={{
                                  borderRight: "2px solid #c50074",
                                }}
                              >
                                <Button
                                  color="secondaryone"
                                  type="button"
                                  className="w-100"
                                  onClick={() => {
                                    setOrderDetailsId(data.orders_main_id);
                                  }}
                                >
                                  {data.shipping_type_requested?.toLowerCase() ===
                                  "local delivery"
                                    ? "🚚"
                                    : data.shipping_type_requested?.toLowerCase() ===
                                      "local pickup"
                                    ? "🛍️"
                                    : data.shipping_type_requested?.toLowerCase() ===
                                      "usps priority mail"
                                    ? "📦"
                                    : "🚫"}{" "}
                                  {data.order_source_ordername}
                                </Button>
                              </strong>
                            </th>
                            {isCustomerNameChecked && (
                              <th className="px-1 px-lg-2 px-xl-3 py-1">
                                <strong>
                                  {data?.customer_first_name +
                                    " " +
                                    data?.customer_last_name}
                                </strong>
                              </th>
                            )}
                            {isCustomerDeliveryZipChecked && (
                              <th className="px-1 px-lg-2 px-xl-3 py-1">
                                <strong>
                                  {data?.shipping_address?.address?.zip}
                                </strong>
                              </th>
                            )}
                            <td className="px-1 px-lg-2 px-xl-3 py-1">
                              {todayDateString === orderDateString
                                ? "Today " + getTimeGMT(data?.order_placed_stc)
                                : getDateGMT(data?.order_placed_stc)}
                            </td>
                            <td className="px-1 px-lg-2 px-xl-3 py-1 text-right">
                              {getCurrencyInUSD(+data?.order_amount_cart)}
                            </td>
                            <td className="px-1 px-lg-2 px-xl-3 py-1 text-right">
                              {getCurrencyInUSD(
                                +data?.order_amount_gratuity +
                                  +data?.order_amount_shipping
                              )}
                            </td>
                            <td className="px-1 px-lg-2 px-xl-3 py-1 text-right">
                              {requoteResult?.estimation_ids?.hasOwnProperty(
                                data.order_source_ordername
                              ) ? (
                                <Button
                                  color="secondaryone"
                                  type="button"
                                  className="w-100"
                                  onClick={() => {
                                    handlePriceClick(data);
                                    setIsPriceDetailModalOpen((prev) => !prev);
                                  }}
                                >
                                  {getCurrencyInUSD(
                                    requoteResult?.estimation_ids[
                                      data.order_source_ordername
                                    ].data?.price
                                  )}
                                </Button>
                              ) : data?.shipping_cost_estimated ? (
                                <Button
                                  color="secondaryone"
                                  type="button"
                                  className="w-100"
                                  onClick={() => {
                                    handlePriceClick(data);
                                    setIsPriceDetailModalOpen((prev) => !prev);
                                  }}
                                >
                                  {getCurrencyInUSD(
                                    data?.shipping_cost_estimated
                                  )}
                                </Button>
                              ) : (
                                <Button
                                  color="secondaryone"
                                  className="w-100"
                                  type="button"
                                  disabled
                                >
                                  N/A
                                </Button>
                              )}
                            </td>
                            <td className="px-1 px-lg-2 px-xl-3 py-1">
                              <Input
                                className="form-control-alternative"
                                min={0}
                                type="number"
                                style={{
                                  width: "75px",
                                }}
                                onChange={(e) =>
                                  handleMarketTipChange(
                                    e,
                                    data.order_source_ordername,
                                    "mkt_tip"
                                  )
                                }
                                value={data?.mkt_tip}
                              />
                            </td>
                            <td className="px-1 px-lg-2 px-xl-3 py-1">
                              <Input
                                className="form-control-alternative"
                                style={{
                                  width: "75px",
                                }}
                                min={0}
                                type="number"
                                onChange={(e) =>
                                  handleMarketTipChange(
                                    e,
                                    data.order_source_ordername,
                                    "intOfr"
                                  )
                                }
                                value={data.intOfr}
                              />
                            </td>
                            <td className="px-1 px-lg-2 px-xl-3 py-1">
                              <Input
                                type="select"
                                value={`${
                                  data?.msa_abbrev
                                    ? data?.msa_abbrev?.toUpperCase()
                                    : ""
                                }`}
                                onChange={(e) => {
                                  handleOptionChange(
                                    e,
                                    data.order_source_ordername
                                  );
                                  setOrderIdForMarket(
                                    data.order_source_ordername
                                  );
                                }}
                                style={{
                                  width: "90px",
                                }}
                              >
                                <React.Fragment>
                                  <option key="" disabled selected value="">
                                    N/A
                                  </option>
                                  <option key="BNA" value="BNA">
                                    BNA
                                  </option>
                                  <option key="ATL" value="ATL">
                                    ATL
                                  </option>
                                  <option key="CHA" value="CHA">
                                    CHA
                                  </option>
                                  <option key="CKV" value="CKV">
                                    CKV
                                  </option>
                                  <option key="ICT" value="ICT">
                                    ICT
                                  </option>
                                </React.Fragment>
                              </Input>
                            </td>
                            <td className="px-1 px-lg-2 px-xl-3 py-1">
                              <Button
                                color="secondaryone"
                                type="button"
                                onClick={() => {
                                  setIsDelInfoModalOpen((prev) => !prev);
                                  setOrderId(data.orders_main_id);
                                  setOrderIdForMarket(
                                    data.order_source_ordername
                                  );
                                }}
                              >
                                Delivery Info
                              </Button>
                            </td>
                            <td className="px-1 px-lg-2 px-xl-3 py-1 text-center">
                              <Button
                                color="secondaryone"
                                type="button"
                                disabled={
                                  dispatchResult?.order_ids?.hasOwnProperty(
                                    data.order_source_ordername
                                  )
                                    ? false
                                    : data.delivery_id
                                    ? false
                                    : true
                                }
                                onClick={() => {
                                  checkStatus(
                                    dispatchResult?.order_ids?.hasOwnProperty(
                                      data.order_source_ordername
                                    )
                                      ? dispatchResult?.order_ids[
                                          data.order_source_ordername
                                        ].data?.id
                                      : data.delivery_id
                                  );
                                }}
                              >
                                {dispatchResult?.order_ids?.hasOwnProperty(
                                  data.order_source_ordername
                                )
                                  ? dispatchResult?.order_ids[
                                      data.order_source_ordername
                                    ].data?.first_delivery_provider === "3PL"
                                    ? "Status"
                                    : dispatchResult?.order_ids[
                                        data.order_source_ordername
                                      ].data?.first_delivery_provider
                                  : data?.localdelivery_driver_display_internal_name ===
                                    "3PL"
                                  ? "Status"
                                  : data?.localdelivery_driver_display_internal_name
                                  ? data?.localdelivery_driver_display_internal_name
                                  : "Status"}
                              </Button>
                            </td>
                            <td className="px-1 px-lg-2 px-xl-3 py-1">
                              <UncontrolledDropdown nav>
                                <DropdownToggle nav>
                                  <img
                                    src={Dots}
                                    alt="menu dropdown"
                                    style={{ height: "27px" }}
                                  />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow"
                                  right
                                >
                                  <DropdownItem
                                    href={
                                      process.env.REACT_APP_SHOPIFY_ADMIN_URL +
                                      "orders/" +
                                      data.order_source_uuid
                                    }
                                    target="_blank"
                                  >
                                    <span>View Order</span>
                                  </DropdownItem>

                                  <DropdownItem
                                    href={
                                      process.env.REACT_APP_SHOPIFY_ADMIN_URL +
                                      "customers/" +
                                      data.customer_id
                                    }
                                    target="_blank"
                                  >
                                    <span>View Customer</span>
                                  </DropdownItem>

                                  {data?.msa_abbrev ? (
                                    <DropdownItem
                                      href={`https://www.google.com/maps/dir/${
                                        data?.msa_abbrev?.toUpperCase() ===
                                        "BNA"
                                          ? bnaFormData?.address1?.replace(
                                              / /g,
                                              "+"
                                            )
                                          : data?.msa_abbrev?.toUpperCase() ===
                                            "ATL"
                                          ? atlFormData?.address1?.replace(
                                              / /g,
                                              "+"
                                            )
                                          : ""
                                      },+USA/${
                                        data?.shipping_address?.address
                                          ?.street1 +
                                        "+" +
                                        data?.shipping_address?.address?.city +
                                        "+" +
                                        data?.shipping_address?.address?.state +
                                        "+" +
                                        data?.shipping_address?.address?.zip
                                      },+USA/@36.1622731,-86.8003192`}
                                      target="_blank"
                                      disabled={false}
                                    >
                                      <span>Route Map</span>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>

                  <CardFooter
                    className={`py-4 custom_transitions ${
                      isTableHide ? "" : "d-none"
                    }`}
                  >
                    {pageLoader ? (
                      <h3 className="text-center">Data loading...</h3>
                    ) : !dataTableArray?.length ? (
                      <h2 className="text-center text-danger">
                        Record not found
                      </h2>
                    ) : (
                      ""
                    )}

                    {dataTableArray?.length ? (
                      <div className="d-flex mb-3 mt-3 flex-column flex-sm-row align-items-center justify-content-start mb-2">
                        <TooltipWrapper
                          id={"tiwerOrcdecrtswerwerewr"}
                          textTwo="Quote"
                        >
                          <ButtonStrap
                            color="secondaryone"
                            type="button"
                            name="Quote"
                            className="mb-2 mb-sm-0 mr-0 mr-sm-3"
                            disabled={requoteLoading || pageLoader}
                            onClick={handleRequoteClick}
                          />
                        </TooltipWrapper>
                        <TooltipWrapper
                          id={"tiaswerOrcdecrtswerwerewr"}
                          textTwo="Dispatch"
                        >
                          <ButtonStrap
                            color="secondaryone"
                            type="button"
                            className="mb-2 mb-sm-0 mr-0 mr-sm-3"
                            name="Dispatch"
                            disabled={requoteLoading || pageLoader}
                            onClick={handleDispatchSelectedOrderClick}
                          />
                        </TooltipWrapper>
                        <TooltipWrapper
                          id={"tiwerOrcdecrtswerwerewr"}
                          textTwo="Export"
                        >
                          <ButtonStrap
                            color="secondaryone"
                            type="button"
                            name="Export"
                            className="mb-2 mb-sm-0"
                            disabled={requoteLoading || pageLoader}
                            onClick={() =>
                              setIsExportModalOpen((prev) => !prev)
                            }
                          />
                        </TooltipWrapper>
                      </div>
                    ) : (
                      ""
                    )}
                    {dataTableArray?.length ? (
                      <nav aria-label="...">
                        <Pagination
                          className="pagination d-flex justify-content-center justify-content-sm-end mb-0"
                          listClassName="justify-content-end mb-0"
                        >
                          <PaginationItem
                            onClick={() =>
                              !pageLoader
                                ? fetchOrderList(1, getOrderListTable)
                                : {}
                            }
                            className={
                              +orderRecordsPagination?.currentPage === 1
                                ? "disabled"
                                : ""
                            }
                          >
                            <TooltipWrapper
                              id={"tiaswewerewewrfghjkl"}
                              textTwo={
                                +orderRecordsPagination?.currentPage !== 1
                                  ? "First Page"
                                  : ""
                              }
                            >
                              <PaginationLink
                                tabIndex={
                                  +orderRecordsPagination?.currentPage === 1
                                    ? "-1"
                                    : ""
                                }
                              >
                                <i className="fas fa-angle-left" />
                                <span className="sr-only">Previous</span>
                              </PaginationLink>
                            </TooltipWrapper>
                          </PaginationItem>

                          {orderRecordsPagination?.currentPage > 1 ? (
                            <PaginationItem
                              onClick={() =>
                                !pageLoader
                                  ? fetchOrderList(
                                      orderRecordsPagination?.prevPage,
                                      getOrderListTable
                                    )
                                  : {}
                              }
                            >
                              <TooltipWrapper
                                id={"tiaswerOrcdecrtswerwerewar"}
                                textTwo="Previous Page"
                              >
                                <PaginationLink>
                                  {orderRecordsPagination?.prevPage}
                                </PaginationLink>
                              </TooltipWrapper>
                            </PaginationItem>
                          ) : (
                            ""
                          )}

                          <PaginationItem className="active">
                            <TooltipWrapper
                              id={"tiaswerOrcdecrtswerwerewaur"}
                              textTwo="Current Page"
                            >
                              <PaginationLink className="bg-secondaryone border-0">
                                {pageLoader ? (
                                  <LoaderComp />
                                ) : (
                                  orderRecordsPagination?.currentPage
                                )}
                              </PaginationLink>
                            </TooltipWrapper>
                          </PaginationItem>

                          {+orderRecordsPagination?.lastPage !==
                          +orderRecordsPagination?.currentPage ? (
                            <PaginationItem
                              onClick={() =>
                                !pageLoader
                                  ? fetchOrderList(
                                      orderRecordsPagination?.nextPage,
                                      getOrderListTable
                                    )
                                  : {}
                              }
                            >
                              <TooltipWrapper
                                id={"tiaswerOrcdecrtswerwerewsaur"}
                                textTwo="Next Page"
                              >
                                <PaginationLink>
                                  {orderRecordsPagination?.nextPage}
                                </PaginationLink>
                              </TooltipWrapper>
                            </PaginationItem>
                          ) : (
                            ""
                          )}

                          <PaginationItem
                            onClick={() =>
                              !pageLoader
                                ? fetchOrderList(
                                    orderRecordsPagination?.lastPage,
                                    getOrderListTable
                                  )
                                : {}
                            }
                            className={
                              +orderRecordsPagination?.currentPage ===
                              +orderRecordsPagination?.lastPage
                                ? "disabled"
                                : ""
                            }
                          >
                            <TooltipWrapper
                              id={"tiaswewerewrfghjkl"}
                              textTwo={
                                +orderRecordsPagination?.currentPage ===
                                +orderRecordsPagination?.lastPage
                                  ? ""
                                  : "Last Page"
                              }
                            >
                              <PaginationLink tabIndex={"-1"}>
                                <i className="fas fa-angle-right" />
                              </PaginationLink>
                            </TooltipWrapper>
                          </PaginationItem>
                        </Pagination>
                      </nav>
                    ) : (
                      ""
                    )}
                  </CardFooter>
                </Card>
              </Col>

              <Col className="mt-4 mt-md-6 px-3 p-0">
                <PageCardComp
                  title="Fulfillment + Pickup"
                  pageCheckbox={
                    <>
                      <div id="app-title">
                        <ToggleSwitchComp
                          name="Show"
                          handleChange={() =>
                            handleToggleChange(setIsPickupBoxHide)
                          }
                        />
                      </div>
                      <TooltipComp
                        textOne={"Select"}
                        textTwo={"Unselect"}
                        anchorId="app-title"
                        varName={!isPickupBoxHide}
                      />
                    </>
                  }
                >
                  <Row
                    className={`w-100 custom_transitions m-0 ${
                      isPickupBoxHide ? "" : "d-none"
                    }`}
                  >
                    <Col sm={12} className="p-0 px-md-3">
                      <div className="row d-flex flex-column">
                        <div className="col">
                          <div
                            className="list-group d-flex flex-row"
                            id="list-tab"
                            role="tablist"
                          >
                            <AddressTabs
                              id="list-home-list"
                              bgColor="#FFA500"
                              toggleState={tabHighlighted}
                              tabNumber={1}
                              onClick={() => setTabHighlighted(1)}
                              name="BNA"
                            />
                            <AddressTabs
                              id="list-home-list"
                              bgColor="#00ff00"
                              toggleState={tabHighlighted}
                              tabNumber={2}
                              onClick={() => setTabHighlighted(2)}
                              name="ATL"
                            />
                            <AddressTabs
                              id="list-messages-list"
                              bgColor="#186F65"
                              toggleState={tabHighlighted}
                              tabNumber={3}
                              onClick={() => setTabHighlighted(3)}
                              name="CHA"
                            />
                            <AddressTabs
                              id="list-settings-list"
                              bgColor="#5C8374"
                              toggleState={tabHighlighted}
                              tabNumber={4}
                              onClick={() => setTabHighlighted(4)}
                              name="ICT"
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="tab-content" id="nav-tabContent">
                            <FulfilmentPickupForm
                              bgFormColor="#FBD590"
                              defaultClass="tab-pane py-3 py-lg-5 fade show"
                              tabNumber={tabHighlighted}
                              activeIndex={1}
                              activeClass="active"
                              formId="list-home"
                              isFDPTrue={isBnaFDPTrue}
                              setIsFDPTrue={setIsBnaFDPTrue}
                              isTabTrue={isBnaTabTrue}
                              setIsTabTrue={setIsBnaTabTrue}
                              formData={bnaFormData}
                              setFormData={setBNAFormData}
                              enableCheckBox={bnaEnableCheckBox}
                              setEnableCheckBox={setBnaEnableCheckBox}
                              overrideDeliveryAdress={bnaOverrideDeliveryAdress}
                              setOverrideDeliveryAdress={
                                setBnaOverrideDeliveryAdress
                              }
                              deliveryProviderArray={bnaDeliveryProvider}
                              selectAddressArray={bnaSelectAddress}
                            />
                            <FulfilmentPickupForm
                              bgFormColor="#90FB90"
                              defaultClass="tab-pane py-3 py-lg-5 fade"
                              tabNumber={tabHighlighted}
                              activeIndex={2}
                              activeClass="show active"
                              formId="list-profile"
                              isFDPTrue={isAtlFDPTrue}
                              setIsFDPTrue={setIsAtlFDPTrue}
                              isTabTrue={isAtlTabTrue}
                              setIsTabTrue={setIsAtlTabTrue}
                              formData={atlFormData}
                              setFormData={setAtlFormData}
                              enableCheckBox={atlEnableCheckBox}
                              setEnableCheckBox={setAtlEnableCheckBox}
                              overrideDeliveryAdress={atlOverrideDeliveryAdress}
                              setOverrideDeliveryAdress={
                                setAtlOverrideDeliveryAdress
                              }
                              deliveryProviderArray={atlDeliveryProvider}
                              selectAddressArray={atlSelectAddress}
                            />
                            <FulfilmentPickupForm
                              bgFormColor="#B5CB99"
                              defaultClass="tab-pane fade py-3 py-lg-5"
                              tabNumber={tabHighlighted}
                              activeIndex={3}
                              activeClass="show active"
                              formId="list-messages"
                              isFDPTrue={isChaFDPTrue}
                              setIsFDPTrue={setIsChaFDPTrue}
                              isTabTrue={isChaTabTrue}
                              setIsTabTrue={setIsChaTabTrue}
                              formData={chaFormData}
                              setFormData={setChaFormData}
                              enableCheckBox={chaEnableCheckBox}
                              setEnableCheckBox={setChaEnableCheckBox}
                              overrideDeliveryAdress={chaOverrideDeliveryAdress}
                              setOverrideDeliveryAdress={
                                setChaOverrideDeliveryAdress
                              }
                              deliveryProviderArray={chaDeliveryProvider}
                              selectAddressArray={chaSelectAddress}
                            />
                            <FulfilmentPickupForm
                              bgFormColor="#9EC8B9"
                              defaultClass="tab-pane fade py-3 py-lg-5"
                              tabNumber={tabHighlighted}
                              activeIndex={4}
                              activeClass="show active"
                              formId="list-settings"
                              isFDPTrue={isIctFDPTrue}
                              setIsFDPTrue={setIsIctFDPTrue}
                              isTabTrue={isIctTabTrue}
                              setIsTabTrue={setIsIctTabTrue}
                              formData={ictFormData}
                              setFormData={setIctFormData}
                              enableCheckBox={ictEnableCheckBox}
                              setEnableCheckBox={setIctEnableCheckBox}
                              overrideDeliveryAdress={ictOverrideDeliveryAdress}
                              setOverrideDeliveryAdress={
                                setIctOverrideDeliveryAdress
                              }
                              deliveryProviderArray={ictDeliveryProvider}
                              selectAddressArray={ictSelectAddress}
                            />
                            {/* <FulfilmentPickupForm
                              defaultClass="tab-pane fade pt-3 pt-lg-5"
                              tabNumber={tabHighlighted}
                              activeIndex={4}
                              activeClass="show active"
                              formId="list-settings"
                           
                            /> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </PageCardComp>
              </Col>
            </Row>
            {/* <Row className="mt-6 mx-0">
              <PageCardComp
                title="Notifications"
                pageCheckbox={
                  <div id="app-title6">
                    <ToggleSwitchComp
                      name="Show"
                      defaultChecked={false}
                      handleChange={() => handleToggleChange(setIsNotifBoxHide)}
                    />

                    <TooltipComp
                      textOne={"Select"}
                      textTwo={"Unselect"}
                      anchorId="app-title6"
                      varName={!isNotifBoxHide}
                    />
                  </div>
                }
              >
                <Row
                  className={`icon-examples custom_transitions mx-0 mt-0 flex-wrap ${
                    !isNotifBoxHide ? "d-flex" : "d-none"
                  }`}
                >
                  <Table
                    className={`align-items-center flex-column table-flush custom_transitions ${
                      isTableHide ? "" : "d-none"
                    }`}
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        <th className="table_padding" scope="col"></th>
                        <th className="table_padding" scope="col"></th>
                        <th className="table_padding" scope="col">
                          Customer
                        </th>
                        <th className="table_padding" scope="col"></th>
                        <th className="table_padding" scope="col"></th>
                        <th className="table_padding" scope="col">
                          Fulfillment
                        </th>
                        <th className="table_padding" scope="col"></th>
                      </tr>
                      <tr
                        style={{
                          position: "relative",
                        }}
                      >
                        <th className="table_padding" scope="col">
                          Event
                        </th>
                        <th className="table_padding" scope="col">
                          SMS
                        </th>

                        <th className="table_padding" scope="col">
                          Push
                        </th>
                        <th className="table_padding" scope="col">
                          Email
                        </th>
                        <th className="table_padding" scope="col">
                          SMS
                        </th>
                        <th className="table_padding" scope="col">
                          Push
                        </th>

                        <th className="table_padding" scope="col">
                          Email
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {customNotifcArray?.map((data, ind) => {
                        return (
                          <tr key={ind}>
                            <th
                              scope="row"
                              className="px-1 px-lg-2 px-xl-3 py-1 pl-0 pl-lg-1"
                            >
                              <strong>{data.name}</strong>
                            </th>
                            <th className="px-1 pl-4 py-2">
                              <TooltipCheckboxComp
                                id={ind + "dfgrdfgd"}
                                checkboxValue={ind % 2 !== 0}
                              />
                            </th>
                            <td className="px-1 pl-4 py-2">
                              <TooltipCheckboxComp
                                id={ind + "dfgdefgd"}
                                checkboxValue={ind % 2 === 0}
                              />
                            </td>
                            <td className="px-1 pl-4 py-2">
                              <TooltipCheckboxComp
                                id={ind + "dfgerdfgd"}
                                checkboxValue={true}
                              />
                            </td>
                            <td className="px-1 pl-4 py-2">
                              <TooltipCheckboxComp
                                id={ind + "dfsdgdfgd"}
                                checkboxValue={ind % 2 !== 0}
                              />
                            </td>
                            <td className="px-1 pl-4 py-2">
                              <TooltipCheckboxComp id={ind + "dfdfgdsdf"} />
                            </td>
                            <td className="px-1 pl-4 py-2">
                              <TooltipCheckboxComp
                                id={ind + "dfgdfgfgdsdf"}
                                checkboxValue={true}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Row>
              </PageCardComp>
            </Row> */}
          </div>
        </Row>
      </Container>

      {requoteLoading || pageLoader ? (
        <div className="dispatcher_loader_class">
          <LoaderComp classNameText="secondaryone" />
        </div>
      ) : (
        ""
      )}

      <ModalComp
        isModalOpen={isModalOpen}
        setIsModalOpen={() => {
          setIsModalOpen((prev) => !prev);
          setMarketData(marketValues);
        }}
        headerName="Markets"
      >
        <Row className="icon-examples mt-0 d-flex flex-column">
          <Form role="form" onSubmit={handleCheckBoxSaveClick}>
            {marketData.map((el, ind) => (
              <Col key={ind} md={3} className="mb-3">
                <RadioWithCheckedComp
                  id={1}
                  name={el.name}
                  checkboxValue={el.isChecked}
                  handleChange={() => handleCheckBoxSaveChange(ind)}
                />
              </Col>
            ))}

            <Col md={12} className="d-flex justify-content-center">
              <ButtonStrap color="secondaryone" type="submit" name="Save" />
            </Col>
          </Form>
        </Row>
      </ModalComp>
      <ModalComp
        isModalOpen={isHamburgerModalOpen}
        setIsModalOpen={() => setIsHamburgerModalOpen((prev) => !prev)}
        headerName="Orders"
      >
        <Row className="icon-examples mt-0 d-flex flex-column">
          <Col md={9} className="mb-3">
            <TooltipCheckboxComp
              checkboxValue={isCustomerNameChecked}
              handleChange={() => dispatch(getUserNameChecked())}
              id={1 + "dfgdfgd"}
              name="Customer Name"
            />
          </Col>
          <Col md={9} className="mb-3">
            <TooltipCheckboxComp
              checkboxValue={isCustomerDeliveryZipChecked}
              handleChange={() => dispatch(getZipCodeChecked())}
              id={2 + "dfgdfgd"}
              name="Delivery Zip Code"
            />
          </Col>
        </Row>
      </ModalComp>
      <ModalComp
        isModalOpen={isDelInfoModalOpen}
        setIsModalOpen={() => setIsDelInfoModalOpen((prev) => !prev)}
        size="lg"
        headerName="Delivery Information"
      >
        <Form role="form" onSubmit={handleSingleOrderSubmit}>
          <Row className="w-100 d-flex flex-wrap m-0">
            <Col sm={12} className="px-2 px-sm-3">
              <FormGroup>
                <Label>Name</Label>
                <Input
                  className="form-control-alternative"
                  value={orderFormData?.name}
                  type="text"
                  required
                  name="name"
                  onChange={(e) => handleOrderDataChange(e, setOrderFormData)}
                />
              </FormGroup>
            </Col>
            <Col md={6} className="px-2 px-sm-3">
              <FormGroup>
                <Label>Input Address</Label>
                <GoogleInput
                  formData={orderFormData}
                  setFormData={setOrderFormData}
                  placeholder={"Enter your Delivery address"}
                />
                {/* <Input
                  className="form-control-alternative"
                  value={orderFormData?.address1}
                  type="text"
                  required
                  name="address1"
                  onChange={(e) => handleOrderDataChange(e, setOrderFormData)}
                /> */}
              </FormGroup>
            </Col>
            {/*  <Col md={6} className="px-2 px-sm-3">
              <FormGroup>
                <Label>Address Line 2</Label>
                <Input
                  className="form-control-alternative"
                  value={orderFormData?.address2}
                  type="text"
                  name="address2"
                  onChange={(e) => handleOrderDataChange(e, setOrderFormData)}
                />
              </FormGroup>
            </Col>

            <Col md={4} className="px-2 px-sm-3">
              <FormGroup>
                <Label>City</Label>
                <Input
                  className="form-control-alternative"
                  value={orderFormData?.city}
                  type="text"
                  name="city"
                  required
                  onChange={(e) => handleOrderDataChange(e, setOrderFormData)}
                />
              </FormGroup>
            </Col>
            <Col md={4} className="px-2 px-sm-3">
              <FormGroup>
                <Label>State</Label>
                <Input
                  className="form-control-alternative"
                  value={orderFormData?.state}
                  type="text"
                  name="state"
                  required
                  onChange={(e) => handleOrderDataChange(e, setOrderFormData)}
                />
              </FormGroup>
            </Col>

            <Col md={4} className="px-2 px-sm-3">
              <FormGroup>
                <Label>Zip</Label>
                <Input
                  className="form-control-alternative"
                  value={orderFormData?.zip}
                  type="text"
                  name="zip"
                  required
                  onChange={(e) => handleOrderDataChange(e, setOrderFormData)}
                />
              </FormGroup>
            </Col> */}
            <Col md={6} className="px-2 px-sm-3">
              <FormGroup>
                <Label>Phone</Label>
                <Input
                  className="form-control-alternative"
                  defaultValue={orderFormData?.phone}
                  type="number"
                  required
                  name="phone"
                  onChange={(e) => handleOrderDataChange(e, setOrderFormData)}
                />
              </FormGroup>
            </Col>

            <Col md={12} className="px-2 px-sm-3">
              <FormGroup>
                <Label>Delivery Instructions/Notes</Label>
                <Input
                  value={orderFormData?.delivery_instruction}
                  name="delivery_instruction"
                  rows="3"
                  type="textarea"
                  onChange={(e) => handleOrderDataChange(e, setOrderFormData)}
                />
              </FormGroup>
            </Col>
            <Col sm={12} className="p-0 d-flex justify-content-end pb-4 pt-5">
              {updateOrderLoader ? (
                <LoaderComp classNameText="secondaryone" />
              ) : (
                ""
              )}
              <ButtonStrap
                color="secondaryone"
                className="ml-3"
                type="submit"
                name="Save"
                disabled={updateOrderLoader}
              />
            </Col>
          </Row>
        </Form>
      </ModalComp>
      <ModalComp
        isModalOpen={isDetailOrderModalOpen}
        setIsModalOpen={() => setIsDetailOrderModalOpen((prev) => !prev)}
        headerName="Order Details"
      >
        <EstimationDetailsRow
          text="Order ID"
          value={orderDetailsData?.order_source_ordername}
        />

        <EstimationDetailsRow
          text="Customer Name"
          value={
            orderDetailsData?.customer_first_name +
            " " +
            orderDetailsData?.customer_last_name
          }
        />
        <EstimationDetailsRow
          text="Delivery Type"
          value={orderDetailsData?.shipping_type_requested || "N/A"}
        />
        <EstimationDetailsRow
          text="Cart Amount"
          value={getCurrencyInUSD(+orderDetailsData?.order_amount_cart)}
        />
        <EstimationDetailsRow
          text="Delivery Amount"
          value={getCurrencyInUSD(+orderDetailsData?.order_amount_shipping)}
        />
        <EstimationDetailsRow
          text="Tip Amount"
          value={getCurrencyInUSD(
            +orderDetailsData?.order_amount_gratuity || 0
          )}
        />
        {orderDetailsData?.items?.length ? (
          <Row className="w-100 d-flex flex-wrap m-0 mb-1 mb-sm-2">
            <h2 className="mt-3 text-center w-100">Cart Items</h2>
            {orderDetailsData?.items?.map((elem, ind) => (
              <div key={ind} className="font-weight-500 mt-1 mt-sm-2">
                {elem?.quantity}x
                <span className="font-weight-600 ml-2">{elem?.name}</span>
              </div>
            ))}
          </Row>
        ) : (
          ""
        )}
      </ModalComp>
      <ModalComp
        isModalOpen={isPriceDetailModalOpen}
        setIsModalOpen={() => setIsPriceDetailModalOpen((prev) => !prev)}
        headerName="Estimation Details"
      >
        <EstimationDetailsRow
          text="Provider"
          value={priceDetailsData?.provider}
        />
        <EstimationDetailsRow
          text="Distance"
          value={priceDetailsData?.distance}
        />
        <EstimationDetailsRow text="Size" value={priceDetailsData?.size} />
        <EstimationDetailsRow
          text="Category"
          value={priceDetailsData?.description}
        />
        <EstimationDetailsRow
          text="Number of Items"
          value={priceDetailsData?.quantity}
        />
      </ModalComp>
      <ModalComp
        isModalOpen={isExportModalOpen}
        setIsModalOpen={handleCSVModalClose}
        headerName="Export Data"
      >
        <Row className={`flex-wrap`}>
          {exportCSVCheckboxArray.map((el, ind) => (
            <Col
              sm={ind % 2 === 0 ? 5 : 7}
              key={ind}
              className="pl-3 pl-md-3 mb-1 mb-md-2"
            >
              <TooltipCheckboxComp
                id={ind + el.isChecked + el.name}
                checkboxValue={el.isChecked}
                name={el.name.toUpperCase()}
                handleChange={() => handleExportCheckboxChanged(ind)}
              />
            </Col>
          ))}
          <Col sm={12} className="d-flex justify-content-center mt-4">
            <TooltipWrapper id={"tiwerOrewcdecrtswerwerewr"} textTwo="Export">
              <ButtonStrap
                color="secondaryone"
                type="button"
                name="Export"
                className="mb-2 mb-sm-0"
                onClick={() => handleCsvDownloadFun()}
              />
            </TooltipWrapper>
          </Col>
        </Row>
      </ModalComp>
    </>
  );
};

export default Dispatcher;
