import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./reducer/auth-reducer";
import { dispatcherSlice } from "./reducer/disptacher-reducer";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    [dispatcherSlice.name]: dispatcherSlice.reducer,
  },
});
