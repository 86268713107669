import React from "react";
import { Modal } from "reactstrap";

const ModalComp = ({
  isModalOpen,
  setIsModalOpen,
  children,
  headerName,
  size = "sm",
}) => (
  <Modal
    className="modal-dialog-centered pr-3 pr-sm-0"
    isOpen={isModalOpen}
    toggle={() => setIsModalOpen()}
    size={size}
  >
    <div className="modal-header">
      <h6
        style={{
          fontSize: "1.2rem",
        }}
        className="modal-title"
        id="modal-title-default"
      >
        {headerName}
      </h6>
      <button
        aria-label="Close"
        className="close"
        data-dismiss="modal"
        type="button"
        onClick={() => setIsModalOpen((prev) => !prev)}
      >
        <span aria-hidden={true}>×</span>
      </button>
    </div>
    <div className="modal-body">{children && children}</div>
  </Modal>
);

export default ModalComp;
